export const
    DYNAMIC_TEMPLATE_START_ONBOARDING = 'DYNAMIC_TEMPLATE_START_ONBOARDING',
    DYNAMIC_TEMPLATE_SHOW_WELCOME_PAGE = 'DYNAMIC_TEMPLATE_SHOW_WELCOME_PAGE',
    DYNAMIC_TEMPLATE_GMB_KEY_UPDATED = 'DYNAMIC_TEMPLATE_GMB_KEY_UPDATED',
    DYNAMIC_TEMPLATE_BUSINESS_NAME_CHANGED = 'DYNAMIC_TEMPLATE_BUSINESS_NAME_CHANGED',
    DYNAMIC_TEMPLATE_KEYWORDS_CHANGED = 'DYNAMIC_TEMPLATE_KEYWORDS_CHANGED',
    DYNAMIC_TEMPLATE_LANGUAGE_CHANGED = 'DYNAMIC_TEMPLATE_LANGUAGE_CHANGED',
    DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO = 'DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO',
    DYNAMIC_TEMPLATE_UPDATE_WEBSITE_PURPOSE = 'DYNAMIC_TEMPLATE_UPDATE_WEBSITE_PURPOSE',
    DYNAMIC_TEMPLATE_UPDATE_ISGROUP = 'DYNAMIC_TEMPLATE_UPDATE_ISGROUP',
    DYNAMIC_TEMPLATE_GET_AI_CONTENT = 'DYNAMIC_TEMPLATE_GET_AI_CONTENT',
    DYNAMIC_TEMPLATE_GET_SECTION_CONTENT = 'DYNAMIC_TEMPLATE_GET_SECTION_CONTENT',
    DYNAMIC_TEMPLATE_SET_UNKNOWN_GMB_KEY = 'DYNAMIC_TEMPLATE_SET_UNKNOWN_GMB_KEY',
    DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY = 'DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY',
    DYNAMIC_TEMPLATE_CALL_ALL_CONCEPT_KEY_CHANGED = 'DYNAMIC_TEMPLATE_CALL_ALL_CONCEPT_KEY_CHANGED',
    DYNAMIC_TEMPLATE_RESET_ERROR_TYPE = 'DYNAMIC_TEMPLATE_RESET_ERROR_TYPE',
    DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA = 'DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA',
    DYNAMIC_TEMPLATE_SET_ADDRESS_URL_FROM_ADDRESS = 'DYNAMIC_TEMPLATE_SET_ADDRESS_URL_FROM_ADDRESS',
    DYNAMIC_TEMPLATE_REGENERATE_TEXT_BUTTON_CLICKED = 'DYNAMIC_TEMPLATE_REGENERATE_TEXT_BUTTON_CLICKED',
    DYNAMIC_TEMPLATE_REGENERATE_TEXT_TRY_AGAIN_CLICKED = 'DYNAMIC_TEMPLATE_REGENERATE_TEXT_TRY_AGAIN_CLICKED',
    WIZARD_STEP_CHANGED = 'WIZARD_STEP_CHANGED',
    TRACK_WIZARD_STEP_CHANGED = 'TRACK_WIZARD_STEP_CHANGED',
    DYNAMIC_TEMPLATE_AGREE_TERMS = 'DYNAMIC_TEMPLATE_AGREE_TERMS',
    DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY = 'DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY',
    HIDE_CATEGORY_ERROR_BANNER = 'HIDE_CATEGORY_ERROR_BANNER',
    GET_CONCEPT_FOR_GMB_KEY_REQUEST = 'GET_CONCEPT_FOR_GMB_KEY_REQUEST',
    GET_CONCEPT_FOR_GMB_KEY_SUCCESS = 'GET_CONCEPT_FOR_GMB_KEY_SUCCESS',
    GET_CONCEPT_FOR_GMB_KEY_FAILED = 'GET_CONCEPT_FOR_GMB_KEY_FAILED',
    GET_GENERATED_AI_CONTENT_REQUEST = 'GET_GENERATED_AI_CONTENT_REQUEST',
    GET_GENERATED_AI_CONTENT_SUCCESS = 'GET_GENERATED_AI_CONTENT_SUCCESS',
    GET_GENERATED_AI_CONTENT_FAILED = 'GET_GENERATED_AI_CONTENT_FAILED',
    GENERATE_SECTION_CONTENT_REQUEST = 'GENERATE_SECTION_CONTENT_REQUEST',
    GENERATE_SECTION_CONTENT_SUCCESS = 'GENERATE_SECTION_CONTENT_SUCCESS',
    GENERATE_SECTION_CONTENT_FAILED = 'GENERATE_SECTION_CONTENT_FAILED',
    TRACK_EVENT_NEXT_GEN_API_FAILURE = 'TRACK_EVENT_NEXT_GEN_API_FAILURE',
    GET_SELECTED_TEMPLATE_REQUEST = 'GET_SELECTED_TEMPLATE_REQUEST',
    GET_SELECTED_TEMPLATE_SUCCESS = 'GET_SELECTED_TEMPLATE_SUCCESS',
    GET_SELECTED_TEMPLATE_FAILED = 'GET_SELECTED_TEMPLATE_FAILED',
    GET_SYNONYMS_FOR_LANGUAGE_REQUEST = 'GET_SYNONYMS_FOR_LANGUAGE_REQUEST',
    GET_SYNONYMS_FOR_LANGUAGE_SUCCESS = 'GET_SYNONYMS_FOR_LANGUAGE_SUCCESS',
    GET_SYNONYMS_FOR_LANGUAGE_FAILED = 'GET_SYNONYMS_FOR_LANGUAGE_FAILED',
    DT_MIXPANEL_GENERATE_PAGE = 'DT_MIXPANEL_GENERATE_PAGE',
    DYNAMIC_PREVIEW_SHOW_PREVIOUS_TEXT = 'DYNAMIC_PREVIEW_SHOW_PREVIOUS_TEXT',
    DYNAMIC_PREVIEW_SHOW_NEXT_TEXT = 'DYNAMIC_PREVIEW_SHOW_NEXT_TEXT',
    DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED = 'DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED',
    DYNAMIC_PREVIEW_CLOSE_TOASTER = 'DYNAMIC_PREVIEW_CLOSE_TOASTER';
