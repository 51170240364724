

export const DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY = 'DASHBOARD_SESSION_DEFAULT_PAGE_ID_KEY';

export const
    setDashboardSessionDefaultPage = (id: string, options?: any) => {
        window.sessionStorage.setItem(
            DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY,
            JSON.stringify({ id, options }),
        );
    },
    getAndClearDashboardSessionDefaultPage = (): { id: string, options?: any } | null => {
        const data = window.sessionStorage.getItem(
            DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY
        );

        if (!data) {
            return null;
        }

        try {
            const { id, options } = JSON.parse(data);

            if (!id) throw new Error();

            window.sessionStorage.removeItem(
                DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY
            );

            return { id, options };
        } catch {
            return null;
        }
    };
