import { DEFAULT_COMPLETE_ADDRESS } from "../constants";
import { DynamicOnboardingEpicState } from "./types";

const currentSectionPath = ['sectionsState', 'currentSection'];
const sectionDataLoadingPath = ['sectionsState', 'sectionDataLoading'];
const sectionGenerationLimitReachedPath = ['sectionsState', 'limitReached'];
const getSectionPath = (sectionId) => ['sectionsState', sectionId];
const getCurrentVersionsPath = (sectionId) => [...getSectionPath(sectionId), 'currentVersion'];
const getIsLastVersionPath = (sectionId) => [...getSectionPath(sectionId), 'isLastVersion'];
const getIsFirstVersionPath = (sectionId) => [...getSectionPath(sectionId), 'isFirstVersion'];
const getSectionCmpIdsPath = (sectionId) => [...getSectionPath(sectionId), 'textComponentIds'];
const getAllVersionsPath = (sectionId) => [...getSectionPath(sectionId), 'versions'];
const getCmpsHtmlPath = (sectionId, version = 0) => [...getSectionPath(sectionId), 'versions', version, 'rawHtml'];
const getCmpsDataPath = (sectionId, version = 0) => [...getSectionPath(sectionId), 'versions', version, 'cmpData'];

const DynamicPreviewPaths = {
    currentSectionPath,
    getSectionPath,
    getCurrentVersionsPath,
    getSectionCmpIdsPath,
    getCmpsHtmlPath,
    getCmpsDataPath,
    sectionDataLoadingPath,
    getIsLastVersionPath,
    getAllVersionsPath,
    getIsFirstVersionPath,
    sectionGenerationLimitReachedPath,
};

const dynamicOnboardingDefaultState: DynamicOnboardingEpicState = {
    catchAllConceptKey: '',
    gmbKey: '',
    businessName: '',
    conceptMap: {},
    concept: {},
    keywords: '',
    language: null,
    purpose: [],
    isNewGmbKey: false,
    contactInfo: {
        phone: '',
        email: '',
        address: '',
        completeAddress: DEFAULT_COMPLETE_ADDRESS,
    },
    isGroup: null,
    isGroupSet: false,
    isLoading: false,
    isSilentLoading: false,
    lastStepOpened: 0,
    showCategoryErrorBanner: false,
    failedAttempt: {
        action: null,
        count: 0
    },
    errorType: null,
    agreeToTerms: false,
    showWelcomePage: true,
    animationData: {
        gmb: {
            isTyping: false,
            key: ''
        },
        contactInfo: {
            email: '',
            phone: '',
            address: '',
            focusedField: null
        },
        keywords: '',
        isForward: true,
    },
    shopDisabled: false,
    selectedTemplate: null,
    showShopWelcome: false,
    synonyms: {},
    isGptDemo: false,
    showGenericConcept: false,
    isMultipageAllowed: false,
    pagesToGenerate: []
};

export {
    DynamicPreviewPaths,
    dynamicOnboardingDefaultState
};
