import makeEpic from '../../../../../epics/makeEpic';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import valueActionType from './valueActionType';
import * as actionTypes from '../actionTypes';
import { FEATURED_PRODUCTS_UPDATE_PRODUCTS } from '../../actionTypes';
import { openDialog, closeDialog } from '../../../../App/actionCreators/index';
import FeaturedProductsSelectorDialogId from '../dialogId';
import {
    getProductsCount,
    getHeight,
    getProductsPerRow
} from '../../utils';
import webshopAdminDialogId from '../../../WebShop/WebshopAdminDialog/dialogId';
import { DialogTypes } from '../../../WebShop/constants';
import { mapShopRouteToUrl } from '../../../../../../../src/dashboard/src/components/Pages/OnlineShop/utils';
import { SHOP_PAGES_ROUTES } from '../../../../../../../src/dashboard/src/components/Pages/OnlineShop/constants';
import unSavedChangesDialogId from '../unSavedChangesDialogId';

const defaultState = {
    featuredProducts: []
};

const addComponentAction = (products, categories, newHeight) => ({
    type: COMPONENT_CONFIGURATION_COMPLETE,
    payload: { products, categories, newHeight }
});
const updateComponentAction = (products, categories) => ({ type: FEATURED_PRODUCTS_UPDATE_PRODUCTS, payload: { products, categories } });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [actionTypes.FEATURED_PRODUCTS_SET_ITEMS],
            reducer: ({ values: [{ isUpdate, products, categories }] }) => {
                let actionToDispatch: AnyAction;
                if (isUpdate) {
                    actionToDispatch = makeActionForwardToSelectedComponent(updateComponentAction(products, categories));
                } else {
                    const productsCount = getProductsCount(products);
                    const productsPerRow = getProductsPerRow(productsCount);
                    const newHeight = getHeight(productsCount, productsPerRow);
                    actionToDispatch = addComponentAction(products, categories, newHeight);
                }

                return { state: defaultState, actionToDispatch };
            }
        }, {
            conditions: [actionTypes.FEATURED_PRODUCTS_SET_ITEMS],
            reducer: () => ({ state: defaultState, actionToDispatch: closeDialog() })
        }, {
            conditions: [actionTypes.OPEN_SHOP_CATEGOREIS],
            reducer: () => ({ state: defaultState,
                multipleActionsToDispatch: [closeDialog(), openDialog(webshopAdminDialogId, {
                    webshopAdminUrl: mapShopRouteToUrl(SHOP_PAGES_ROUTES.CATEGORIES),
                    dialogType: DialogTypes.MANAGE_SHOP
                })] })
        }, {
            conditions: [actionTypes.SHOW_UNSAVED_CHANGES_DIALOG],
            reducer: ({ state, values: [{ params }] }) => {
                return ({
                    state,
                    actionToDispatch: openDialog(unSavedChangesDialogId, params)
                });
            }
        }, {
            conditions: [actionTypes.CLOSE_UNSAVED_CHANGES_DIALOG],
            reducer: ({ state }) => {
                return ({
                    state,
                    actionToDispatch: closeDialog()
                });
            }
        }, {
            conditions: [actionTypes.SHOW_FEATURED_PRODUCTS_SELECTOR_DIALOG],
            reducer: ({ state, values: [{ featuredProducts, categories }] }) => {
                return ({
                    state,
                    actionToDispatch: openDialog(FeaturedProductsSelectorDialogId, { featuredProducts, categories })
                });
            }
        }, {
            conditions: [actionTypes.HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state
                    },
                    actionToDispatch: closeDialog()
                };
            }
        }
    ]
});
