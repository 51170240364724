/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import useReduxEffect from '../../../redux/effects/useReduxEffect';

import {
    filterOutChildComponents,
    getBorderStyles,
    groupTextComponents,
    isTextComponent,
    isCordsEnclosedInBorder,
    getValidTextComponents,
    isStaticTextComponent,
    VERTICAL_PADDING,
    HORIZONTAL_PADDING
} from './previewEditorUtils';
import { useIntl } from '../../../view/intl';

import styles from "./previewEditor.css";
import type { BorderGroup } from "./types";
import { DYNAMIC_PREVIEW_SHOW_NEXT_TEXT, DYNAMIC_PREVIEW_SHOW_PREVIOUS_TEXT,
    DYNAMIC_TEMPLATE_REGENERATE_TEXT_BUTTON_CLICKED,
    GENERATE_SECTION_CONTENT_FAILED,
    GENERATE_SECTION_CONTENT_SUCCESS } from '../../Onboarding/Dynamic/Epic/actionTypes';

import { dynamicPreviewSectionsStateSelector, staticTextComponentsSelector } from '../../Onboarding/Dynamic/Epic/selectors';
import LoadingIndicator from '../../../view/common/LoadingIndicator';
import { CustomTooltip } from '../../../customTooltip/initCustomTooltips';

const BUTTON_HEIGHT = 30;
const BUTTON_MARGIN = 8;
const SCROLL_YOFFSET = 100;
const PIXELS_IN_VIEW = 500;

let stickyHeaderHeight = 0;
let borderTimeOutId;

export const PreviewEditor = ({ iframe, html }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const [sectionBorderMap, setSectionBorderMap] = useState<any>({});
    const [borderGroups, setBorderGroups] = useState<Array<BorderGroup>>([]);
    const [buttonStyle, _setButtonStyle] = useState<any>({});
    const [sectionId, setSectionId] = useState<string | null>(null);

    const selectedParentTextComponentId = useRef<(() => void) | string | null>(null);

    const staticTextComponents = useSelector(staticTextComponentsSelector) || [];
    const sectionState = useSelector(dynamicPreviewSectionsStateSelector);
    const { sectionDataLoading, limitReached } = sectionState;
    const iframeWindow = iframe?.contentWindow;

    const SectionSelector = useMemo(() => 'div[data-specific-kind="SECTION"]', []);
    const TextSelector = useMemo(() => 'div[data-specific-kind="TEXT"]', []);
    const getComponentByIdSelector = (id: string) => `[data-id="${id}"]`;

    const getValidTextComponentsInSection = (iframe, sectionId, staticTextComponents) => {
        const $textComponents = [
            ...(iframe.contentDocument.querySelectorAll(`${SectionSelector}${getComponentByIdSelector(sectionId)} ${TextSelector}`) || [])
        ];
        return getValidTextComponents($textComponents, staticTextComponents)
            .reduce((acc, $ele) => ({ ...acc, [$ele.attributes['data-id'].value]: $ele.outerHTML }), {});
    };

    const updateSectionTextView = (iframe, html, sectionState) => {
        const { currentSection, [currentSection]: { textComponentIds } } = sectionState;
        const newView = document.createElement('div');
        newView.innerHTML = html;

        textComponentIds.forEach((id) => {
            const cmpSelector = `${TextSelector}${getComponentByIdSelector(id)}`;
            const $newEle = newView.querySelector(cmpSelector);
            const $oldEle = iframe.contentDocument.querySelector(cmpSelector);
            if ($newEle && $oldEle) {
                $oldEle.innerHTML = $newEle.innerHTML;
            }
        });
    };

    const replaceComponents = (iframe, cmpsHTML = {}) => {
        Object.keys(cmpsHTML).forEach((id) => {
            const cmpSelector = `${TextSelector}${getComponentByIdSelector(id)}`;
            const $ele = iframe.contentDocument.querySelector(cmpSelector);
            if ($ele) {
                $ele.innerHTML = cmpsHTML[id];
            }
        });
    };

    const calcWindowScrollX = () => {
        return iframeWindow?.scrollX || 0;
    };

    const calcWindowScrollY = () => {
        return iframeWindow?.scrollY || 0;
    };

    const isSectionLoading = () => {
        return sectionDataLoading;
    };

    const calcTop = (style) => {
        let top = style.top - (BUTTON_HEIGHT + BUTTON_MARGIN);
        if (top < (stickyHeaderHeight + calcWindowScrollY())) {
            top = style.top + style.height + BUTTON_MARGIN;
        }

        return top;
    };

    const setButtonStyle = (style) => {
        const top = calcTop(style);

        _setButtonStyle({ ...style, top });
    };

    const resetBorders = () => {
        setBorderGroups([]);
        _setButtonStyle({});
    };

    const calcBorders = (borderStyles, isFirstProcess) => {
        let borders: Array<BorderGroup> = [];
        let found = false;

        for (let i = 0; i < borderStyles.length; i++) {
            let style = borderStyles[i];
            let borderStyle = { ...style, left: style.left + calcWindowScrollX(), top: style.top + calcWindowScrollY() };
            borders.push({ borderStyle, active: false });

            let condition = isFirstProcess ? style.top + style.height > stickyHeaderHeight : isCordsEnclosedInBorder(coords, style);
            if (!found && condition) {
                found = true;
                setButtonStyle(borderStyle);
                borders[i].active = true;
            }
        }

        if (!found && borders.length) {
            borders[0].active = true;
            setButtonStyle(borders[0].borderStyle);
        }

        return borders;
    };

    const updateBorders = (borderStyles) => {
        let borders: Array<BorderGroup> = [];
        for (let i = 0; i < borderGroups.length; i++) {
            let borderGroup = borderGroups[i];
            let style = borderStyles[i];
            let borderStyle = { ...style, left: style.left + calcWindowScrollX(), top: style.top + calcWindowScrollY() };
            borders.push({ borderStyle, active: borderGroup.active });

            if (borderGroup.active) {
                setButtonStyle(borderStyle);
            }
        }

        return borders;
    };

    const getBordersFromSection = (parentSectionId) => {
        let borders: Array<BorderGroup> = [];
        let sectionBorders: Array<BorderGroup> = sectionBorderMap[parentSectionId].borders;
        let found = false;

        for (let i = 0; i < sectionBorders.length; i++) {
            let border = sectionBorders[i];
            let borderStyle = border.borderStyle;
            let style = { ...borderStyle, left: borderStyle.left - calcWindowScrollX(), top: borderStyle.top - calcWindowScrollY() };
            let active = false;

            if (!found && isCordsEnclosedInBorder(coords, style)) {
                found = true;
                setButtonStyle(borderStyle);
                active = true;
            }

            borders.push({ borderStyle, active });
        }

        if (found) {
            return { borders, shouldUpdate: true };
        } else {
            return { borders: sectionBorders, shouldUpdate: false };
        }
    };

    const getTextComponentFromId = (id) => {
        const iframeDocument = iframe?.contentDocument;
        return iframeDocument.querySelector(`div[data-specific-kind="TEXT"][data-id="${id}"]`);
    };

    const getParentTextComponent = (element) => {
        let parentTextComponent: HTMLElement | null = null;
        if (element?.attributes['data-specific-kind']?.value === 'TEXT') {
            parentTextComponent = element;
        } else if (element?.closest('div[data-specific-kind="TEXT"]')) {
            parentTextComponent = element?.closest('div[data-specific-kind="TEXT"]');
        }

        return parentTextComponent;
    };

    const getActiveParentTextComponent = () => {
        let parentTextComponent: HTMLElement | null = null;
        const iframeDocument = iframe.contentDocument;

        for (let i = 0; i < borderGroups.length; i++) {
            let borderGroup = borderGroups[i];
            if (borderGroup.active) {
                let style = borderGroup.borderStyle;
                const left = style.left - calcWindowScrollX() + HORIZONTAL_PADDING;
                const top = style.top - calcWindowScrollY() + VERTICAL_PADDING;
                let element = iframeDocument.elementFromPoint(left, top);

                if (element) {
                    parentTextComponent = getParentTextComponent(element);
                    break;
                }
            }
        }

        return parentTextComponent;
    };

    const processElement = (element, isFirstProcess = false, updateStyle = false) => {
        const iframeDocument = iframe.contentDocument;
        if (!element || isSectionLoading()) {
            return;
        }

        let parentTextComponent = getParentTextComponent(element);

        if (parentTextComponent && !iframeDocument.body.contains(parentTextComponent)) {
            parentTextComponent = getActiveParentTextComponent();
        }

        if (parentTextComponent === null || isStaticTextComponent(parentTextComponent, staticTextComponents)) {
            return;
        } else {
            const textComponentId = parentTextComponent.getAttribute('data-id');
            selectedParentTextComponentId.current = textComponentId;
            if (borderTimeOutId && !isFirstProcess) {
                clearTimeout(borderTimeOutId);
            }
        }

        const parentSection = parentTextComponent.closest('div[data-specific-kind="SECTION"]');
        if (!parentSection) {
            return;
        }

        const parentSectionId = parentSection.getAttribute('data-id');
        if (!parentSectionId) {
            return;
        }

        if (!updateStyle && (parentSectionId in sectionBorderMap)) {
            const { borders, shouldUpdate } = getBordersFromSection(parentSectionId);
            if (shouldUpdate) {
                if (borderTimeOutId) {
                    clearTimeout(borderTimeOutId);
                }
                if (!selectedParentTextComponentId.current) {
                    selectedParentTextComponentId.current = sectionBorderMap[parentSectionId].parentTextComponent;
                }
                setBorderGroups(borders);
                setSectionBorderMap({
                    ...sectionBorderMap,
                    [parentSectionId]: {
                        ...sectionBorderMap[parentSectionId],
                        parentTextComponent: selectedParentTextComponentId.current,
                        borders,
                    }
                });
                setSectionId(parentSectionId);
            }
            return;
        }

        setSectionId(parentSectionId);
        const allChildComponents = parentSection.querySelectorAll('div[data-kind="Component"]');
        const allChildBlocks = parentSection.querySelectorAll('div[data-kind="Block"]:not([class*="Preview_mobileHide"]');

        const filteredChildComponents = filterOutChildComponents(allChildComponents);

        const textComponents = getValidTextComponents(filteredChildComponents, staticTextComponents);
        const groupedTextComponents = groupTextComponents(textComponents);
        const otherComponents = filteredChildComponents.filter(component => !isTextComponent(component));

        let borderStyles = getBorderStyles(groupedTextComponents, otherComponents, allChildBlocks);
        let borders: Array<BorderGroup>;
        if (updateStyle && borderGroups.length) {
            borders = updateBorders(borderStyles);
        } else {
            borders = calcBorders(borderStyles, isFirstProcess);
        }

        setBorderGroups(borders);
        if (updateStyle) {
            setSectionBorderMap({
                [parentSectionId]: {
                    borders,
                    parentTextComponent
                }
            });
        } else {
            setSectionBorderMap({
                ...sectionBorderMap,
                [parentSectionId]: {
                    borders,
                    parentTextComponent
                }
            });
        }
    };

    const calcStickyHeaderHeight = (document) => {
        const header = document.querySelector("div[id='Header'][class*='is-sticky']");
        if (header) {
            stickyHeaderHeight = header.getBoundingClientRect().height;
        }
    };

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();

        return (
            rect.top >= stickyHeaderHeight &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const processElementInViewPort = () => {
        // @ts-ignore
        const iframeDocument = iframe.contentDocument;
        if (iframeDocument) {
            calcStickyHeaderHeight(iframeDocument);
            const allTextComponents = iframeDocument.querySelectorAll('div[data-specific-kind="TEXT"]');
            const textComponents = getValidTextComponents(Array.from(allTextComponents), staticTextComponents);

            for (let i = 0; i < textComponents.length; i++) {
                let element = textComponents[i];

                if (isInViewport(element)) {
                    // @ts-ignore
                    borderTimeOutId = setTimeout(() => {
                        resetBorders();
                    }, 5000);
                    processElement(element, true);
                    // eslint-disable-next-line no-loop-func
                    return () => clearTimeout(borderTimeOutId);
                }
            }
        }

        return null;
    };

    const processCoords = () => {
        if (coords.x > 0 && coords.y > 0) {
            // @ts-ignore
            const iframeDocument = iframe.contentDocument;
            if (!iframeDocument) { return; }

            calcStickyHeaderHeight(iframeDocument);
            const element = iframeDocument.elementFromPoint(coords.x, coords.y);
            processElement(element);
        }
    };

    const handleWindowMouseMove = event => {
        setCoords({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleWindowResize = () => {
        const textComponent = getTextComponentFromId(selectedParentTextComponentId.current);
        processElement(textComponent, false, true);
    };

    useEffect(() => {
        const iframeWindow = iframe.contentWindow;
        if (!iframeWindow) { resetBorders(); return () => null; }

        if (selectedParentTextComponentId.current) {
            let textComponent = getTextComponentFromId(selectedParentTextComponentId.current);
            processElement(textComponent, false, true);
        } else {
            processElementInViewPort();
        }

        iframeWindow.addEventListener('mousemove', handleWindowMouseMove);
        iframeWindow.addEventListener('resize', handleWindowResize);
        const { registerTooltip, unregisterTooltip } = CustomTooltip(window.$, iframeWindow, true);
        registerTooltip(iframeWindow.document);

        return () => {
            iframeWindow.removeEventListener('mousemove', handleWindowMouseMove);
            iframeWindow.removeEventListener('resize', handleWindowMouseMove);
            unregisterTooltip(iframeWindow.document);
            selectedParentTextComponentId.current = null;
        };
    }, [iframe.contentWindow]);

    useEffect(() => {
        if (!isSectionLoading()) {
            processCoords();
        }
    }, [coords]);

    useEffect(() => {
        if (!sectionState.currentSection || !iframe.contentWindow) {
            return;
        }
        const { currentSection, [currentSection]: {
            currentVersion,
            versions: { [currentVersion]: sectionData }
        }, } = sectionState;

        const textComponent = getTextComponentFromId(selectedParentTextComponentId.current);

        if (!sectionData) {
            updateSectionTextView(iframe, html, sectionState);
            processElement(textComponent, false, true);
            return;
        }
        const { rawHtml } = sectionData;
        replaceComponents(iframe, rawHtml);
        processElement(textComponent, false, true);
    }, [iframe, html, sectionState]);

    useReduxEffect(() => {
        const { currentSection: sectionId } = sectionState;
        if (!sectionId || !iframe.contentWindow) {
            return;
        }
        const iframeDocument = iframe.contentDocument;

        const selectedCmp = iframeDocument.querySelector(`${SectionSelector}${getComponentByIdSelector(sectionId)}`);

        if (!selectedCmp) return;

        const { innerHeight } = iframeWindow;
        const { top, height } = selectedCmp.getBoundingClientRect();
        const isPartialElementInViewport = () => {
            if (top > 0 && ((top + (height / 2) < innerHeight) || top + PIXELS_IN_VIEW < innerHeight)) {
                return true;
            }
            return false;
        };
        const scrollOffset = () => {
            if (height < innerHeight) return (innerHeight - height) / 2;
            return stickyHeaderHeight + SCROLL_YOFFSET;
        };

        if (!isPartialElementInViewport()) {
            iframeWindow.oneJQuery.fn.scrollIntoView(selectedCmp, scrollOffset(), () => {}, 1000);
        }
    }, [GENERATE_SECTION_CONTENT_SUCCESS, GENERATE_SECTION_CONTENT_FAILED], [sectionState]);

    const makeStyle = (style) => {
        return {
            top: `${style.top}px`,
            left: `${style.left}px`,
            width: `${style.width}px`,
            height: `${style.height}px`
        };
    };

    const makeButtonStyle = (style) => {
        return {
            top: `${style.top}px`,
            left: `${style.left}px`
        };
    };

    const arrowClicked = (left) => {
        const type = left ? DYNAMIC_PREVIEW_SHOW_PREVIOUS_TEXT : DYNAMIC_PREVIEW_SHOW_NEXT_TEXT;
        dispatch({ type,
            payload: { sectionId, cmpsHTML: getValidTextComponentsInSection(iframe, sectionId, staticTextComponents) } });
    };

    const limitReachedText =
        intl.msgJoint(`msg: onboarding.textGeneration.limitReached {You’ve reached your limit for generating new texts.}`);

    if (!iframeWindow) {
        return null;
    }
    return (
        <div className={styles.previewEditorContainer} data-testid="preview-editor-container">
            {
                borderGroups.map(borderGroup => {
                    let style = borderGroup.borderStyle;
                    const showLeftArrow = borderGroup.active && sectionState?.[sectionId || '']?.isFirstVersion === false;
                    const showRightArrow = borderGroup.active && sectionState?.[sectionId || '']?.isLastVersion === false;
                    const isDisabled = isSectionLoading() || limitReached;
                    return (
                        <React.Fragment>
                            <div className={styles.previewEditorBorder} style={makeStyle(style)}>
                                <div className={cx(styles.borderContainer)}>
                                    {showLeftArrow && <div
                                        data-title={intl.msgJoint('msg: common.previous {Previous}')}
                                        data-title-position="top"
                                        className={cx(styles.showLeftArrow, styles.arrow, { [styles.disable]: isSectionLoading() })}
                                        onClick={() => arrowClicked(true)}
                                    />}
                                    {showRightArrow && <div
                                        data-title={intl.msgJoint('msg: common.next {Next}')}
                                        data-title-position="top"
                                        className={cx(styles.arrow, styles.showRightArrow, { [styles.disable]: isSectionLoading() })}
                                        onClick={() => arrowClicked(false)}
                                    /> }

                                </div>
                                {isSectionLoading() && <LoadingIndicator className={styles.loading} data-testid="preview-editor-loader" />}
                            </div>
                            {
                                (borderGroup.active && buttonStyle) ? (
                                    <div
                                        className={cx(styles.regenerateButton, { [styles.disable]: isDisabled })}
                                        onClick={() => !isDisabled && dispatch({ type: DYNAMIC_TEMPLATE_REGENERATE_TEXT_BUTTON_CLICKED,
                                            payload: {
                                                sectionId,
                                                cmpsHTML: getValidTextComponentsInSection(iframe, sectionId, staticTextComponents)
                                            } })}
                                        style={makeButtonStyle(buttonStyle)}
                                        data-title={limitReached ? limitReachedText : undefined}
                                        data-title-position="top"
                                    >
                                        {intl.msgJoint("msg: onboarding.theming.regenerateText {Regenerate text}")}
                                    </div>
                                ) : null
                            }
                        </React.Fragment>
                    );
                })
            }
        </div>
    );
};
