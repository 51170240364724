/* eslint-disable max-len */
import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import styles from './dynamicPreviewToaster.css';
import { Msg } from '../../../view/intl';
import { ERROR_TYPES } from './view/ErrorModels/constants';
import { addHtmlToasterMessageWithKey, deleteMessageWithKey } from '../../Toaster/actionCreators';
import MessageTypes from '../../Toaster/MessageTypes';
import { DYNAMIC_TEMPLATE_REGENERATE_TEXT_TRY_AGAIN_CLICKED } from './Epic/actionTypes';
import { DYNAMIC_PREVIEW_TOASTER_TYPES } from './constants';

export const DynamicPreviewToasterHtml = ({ errorType }) => {
    const dispatch = useDispatch();
    if (errorType === DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS) {
        return (
            <div className={styles.successToast}>
                <Msg k="dynamicPreviewToaster.success.msg">New text successfully generated.</Msg>
            </div>

        );
    }
    let title, msg, showTryAgain = false, showReminder = true;
    switch (errorType) {
        case ERROR_TYPES.DYNAMIC_TEMPLATES_LIMIT_ERROR:
            title = (<Msg k="common.limitReached">Limit reached</Msg>);
            msg = (<Msg k="dynamicPreviewToaster.limitReached.msg">You’ve reached your maximum usage limit for today. Please use the existing text and proceed to the editor.</Msg>);
            break;
        case ERROR_TYPES.DYNAMIC_TEMPLATES_HIGH_TRAFFIC_ERROR:
            title = (<Msg k="common.trafficError">Traffic error</Msg>);
            msg = (<Msg k="dynamicPreviewToaster.trafficError.msg">Due to high traffic at OpenAI, we can’t process your request. Please try again.</Msg>);
            showTryAgain = true;
            break;
        case ERROR_TYPES.DYNAMIC_TEMPLATES_CONNECTION_ERROR:
            title = (<Msg k="common.connectionError">Connection Error</Msg>);
            msg = (<Msg k="dynamicPreviewToaster.connectionError.msg">Unable to connect to OpenAI at the moment. Please try again.</Msg>);
            showTryAgain = true;
            break;
        case "repeatError":
            title = (<Msg k="common.couldntGenerateText">Couldn’t generate new texts</Msg>);
            msg = (<Msg k="dynamicPreviewToaster.couldntGenerateText.msg">An error has occurred and we cannot generate new texts in the preview at the moment. Please continue with this template. You can use our AI-powered Writing Assistant inside the editor later to create new texts. </Msg>);
            showReminder = false;
            break;
        case DYNAMIC_PREVIEW_TOASTER_TYPES.MULTIPAGE_LOAD_ERROR:
            msg = (<Msg k="dynamicPreviewToaster.multipageLoadError.msg">Couldn’t load the page.</Msg>);
            showReminder = false;
            break;
        case DYNAMIC_PREVIEW_TOASTER_TYPES.MULTIPAGE_IMPORT_ERROR:
            msg = (<Msg k="dynamicPreviewToaster.multipageImportError.msg">Couldn’t load the editor. Click “Apply” to try again.</Msg>);
            showReminder = false;
            break;
        default:
            break;
    }
    return (
        <div className={styles.connectionFailToast}>
            <div className={showTryAgain ? styles.divWithButton : styles.divWithoutButton}>
                {title ? <div className={styles.connectionFailTitleContainer}>
                    {title}
                </div> : null}
                <div
                    className={styles.connectionFailMsgContainer}
                >
                    {msg}
                    {showReminder && <Msg
                        k="dynamicPreviewToaster.writingAssistReminder.msg"
                    > You can also create new texts with our AI-powered Writing Assistant inside the editor later.</Msg>}
                </div>
            </div>
            {showTryAgain && <Button
                variant="contained"
                color="primary"
                className={styles.tryBtn}
                onClick={() => dispatch({ type: DYNAMIC_TEMPLATE_REGENERATE_TEXT_TRY_AGAIN_CLICKED })}
            >
                <Msg k="common.tryAgain">Try again</Msg>
            </Button>}
        </div>);
};
const closeDynamicPreviewToaster = () => {
    return [deleteMessageWithKey(DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS_TOASTER_KEY), deleteMessageWithKey(DYNAMIC_PREVIEW_TOASTER_TYPES.ERROR_TOASTER_KEY)];
};

const dynamicPreviewToaster = (errorType) => {
    let msgStyles, type = MessageTypes.ERROR;
    switch (errorType) {
        case DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS:
            msgStyles = styles.successToasterContainer;
            type = MessageTypes.SUCCESS;
            break;
        case ERROR_TYPES.DYNAMIC_TEMPLATES_LIMIT_ERROR:
        case DYNAMIC_PREVIEW_TOASTER_TYPES.REPEAT_ERROR:
            msgStyles = styles.limitReachedToasterContainer;
            break;
        default:
            msgStyles = styles.connectionFailToasterContainer;
            break;
    }

    const View = <DynamicPreviewToasterHtml errorType={errorType} />;
    return addHtmlToasterMessageWithKey(
        View,
        '',
        msgStyles,
        errorType === DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS ? styles.successClose : styles.errorClose,
        errorType !== DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS,
        type,
        errorType === DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS ? DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS_TOASTER_KEY : DYNAMIC_PREVIEW_TOASTER_TYPES.ERROR_TOASTER_KEY
    );
};

export { dynamicPreviewToaster, closeDynamicPreviewToaster };
