/* eslint-disable max-classes-per-file */

import React from "react";
import cx from "classnames";
import * as styles from './Preview.css';
import { DESIGN_DT_PREFIX, ViewTypes } from "../../constants";
import { Msg } from "../../../../view/intl/index";
import * as Actions from "../../actionTypes";
import { TopBarHeight } from "../../../TopBar/constants";
import TopBarPreview from './TopBarPreview';
import { OnboardingTheming } from "../../../Onboarding/view/OnboardingTheming/index";
import { RightSideTemplatePreviewView } from "../../../Onboarding/view/RightSideTemplatePreviewView";
import { upgrade } from "../../../ComponentTierManager/actionCreators";
import onboardingStyles from '../../../Onboarding/view/styles.css';
import previewStepStyles from '../../../Onboarding/view/PreviewStep.css';
import { SubscriptionType } from '../../../../../../server/shared/subscriptionStatus/constants';
import { getPremiumPackageNameFromMetadata } from '../../../ComponentTierManager/utils';
import DesktopPreview from "./Desktop";
import MobilePreview from "./Mobile";
import { PremiumBanner } from "./PremiumBanner";

import type { PreviewProps } from "../../flowTypes";
import { DT_MIXPANEL_GENERATE_PAGE, DYNAMIC_TEMPLATE_GET_AI_CONTENT } from "../../../Onboarding/Dynamic/Epic/actionTypes";

const View = (props) => {
    const style = {
        top: props.showPremium ? (TopBarHeight + 40) : TopBarHeight
    };
    return (<DesktopPreview {...props} style={style} />);
};

const PremiumMsg = ({ dispatch, hide, subscriptionMetadata, intl }) => {
    const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

    return (
        <div className={cx(styles.premiumMsgContainer, styles.border, { [styles.hide]: hide })}>
            <div className={styles.premiumSymbol} />
            <Msg
                className={styles.premiumText}
                k="templateSelector.premiumTemplate"
                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
            >{`
                This is a {packageName} template
            `}</Msg>
            <Msg
                onClick={
                    () => dispatch(upgrade({
                        upgradeEventLabel: 'TemplateSelector_DEPRECATED:UpgradeBtn',
                        upgradeType: SubscriptionType.PREMIUM
                    }))
                }
                k="common.learnMoreAboutPremium"
                className={styles.premiumLink}
                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
            >{`
                Learn more about {packageName}
            `}</Msg>
        </div>
    );
};

const shouldGeneratePageData = (themePreviewDataForOnboarding, currentPageId) => {
    const allPages = themePreviewDataForOnboarding.computedDataPageSetForNewOnBoarding.pages;
    return !allPages.some(page => page.id === currentPageId);
};

let timeoutId: NodeJS.Timeout | null = null;
export default class Preview extends React.PureComponent<PreviewProps, any> {
    constructor(props: PreviewProps) {
        super(props);
        this.state = {
            hidePremiumBar: false
        };

        this.onScroll = this.onScroll.bind(this);
    }

    handlePremiumBar(scrollPos: number) {
        if (this.state.hidePremiumBar && scrollPos) {
            return;
        }
        this.setState({
            hidePremiumBar: !!scrollPos
        });
    }

    onScroll(e: any) {
        const element = e.target;

        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }

        timeoutId = setTimeout(() => {
            const scrollingLength = element.scrollHeight - element.getBoundingClientRect().height,
                premiumBarHeight = 42;
            if (scrollingLength >= premiumBarHeight || element.scrollTop === 0) {
                this.handlePremiumBar(element && element.scrollTop);
            }
        }, 50);
    }

    render() {
        const
            {
                src, viewType, isNormalType, template = {}, backButtonActionType, dispatch,
                subscriptionMetadata, intl, isSelectedFromTrial
            } = this.props,
            { isPremium } = template,
            isDynamicTemplate = (template && (template.accountName || '').indexOf(DESIGN_DT_PREFIX) !== -1),
            sctaActionHandler = isSelectedFromTrial ?
                Actions.RETURN_TO_ONBOARDING :
                Actions.ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE,
            onboardingThemingFooterDefaultSctaHandlerAction = isDynamicTemplate ?
                backButtonActionType : sctaActionHandler,
            onboardingThemingFooterDefaultMctaHandlerAction = isDynamicTemplate ?
                Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK : Actions.ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE,
            showPremium = (isNormalType && isPremium);

        const changeThemePreviewPageHandler = (pageId) => {
            if (
                isDynamicTemplate &&
                shouldGeneratePageData(this.props.themePreviewDataForOnboarding, pageId)
            ) {
                dispatch({ type: DYNAMIC_TEMPLATE_GET_AI_CONTENT, payload: { pagesToGenerate: [pageId] } });
                dispatch({ type: DT_MIXPANEL_GENERATE_PAGE });
            } else {
                dispatch({ type: Actions.TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE_ADD_TEMPLATE, payload: { pageId } });
            }
        }

        let PreviewView;
        if (!this.props.autoColorMode) {
            if (viewType === ViewTypes.desktop) {
                PreviewView = <View src={src} viewType={viewType} showPremium={showPremium} />;
            } else {
                PreviewView = <MobilePreview
                    {...this.props}
                    showPremium={showPremium}
                    hidePremiumBar={this.state.hidePremiumBar}
                    onScroll={this.onScroll}
                />;
            }
        } else {
            PreviewView = <div className={onboardingStyles.container}>
                <div className={onboardingStyles.content}>
                    <div className={onboardingStyles.contentContainer}>
                        <div className={
                            cx(
                                onboardingStyles.leftContentContainer,
                                previewStepStyles.leftContainerColorPicker
                            )
                        }
                        >
                            <OnboardingTheming
                                dispatch={dispatch}
                                selectedColorPickerTheme={this.props.selectedColorPickerTheme}
                                selectedColorPickerPalette={this.props.selectedColorPickerPalette}
                                changeColorPickerThemeMethodActionType={Actions.CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE}
                                onboardingThemingFooterCustomMctaHandler={Actions.CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE}
                                onboardingThemingFooterCustomSctaHandler={Actions.CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE}
                                onboardingThemingFooterDefaultMctaHandler={onboardingThemingFooterDefaultMctaHandlerAction}
                                // @ts-ignore
                                onboardingThemingFooterDefaultSctaHandler={onboardingThemingFooterDefaultSctaHandlerAction}
                                changeSelectedColorPaletteActionType={Actions.CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE}
                                changeColorPickerCustomThemeColorActionType={Actions.CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE}
                                viewType={viewType}
                                changePreviewActionType={Actions.CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE}
                            />
                        </div>
                        <div className={styles.previewContainer}>
                            {!isDynamicTemplate && <PremiumBanner template={template} />}
                            <RightSideTemplatePreviewView
                                themePreviewDataForOnboarding={this.props.themePreviewDataForOnboarding}
                                themingTemplatePreviewMode={viewType}
                                changeThemePreviewPage={changeThemePreviewPageHandler}
                                generalData={this.props.generalGlobalData}
                                uiLocale={this.props.locale2}
                                dynamicTemplateSelected={isDynamicTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>;
        }

        return (
            <div className={styles.container} key={src}>
                {
                    !this.props.autoColorMode &&
                    <React.Fragment>
                        <TopBarPreview
                            mobileView={viewType !== ViewTypes.desktop}
                            template={template}
                            backButtonActionType={backButtonActionType}
                            themeColors={null}
                        />
                        {isNormalType && isPremium && <PremiumMsg
                            dispatch={dispatch}
                            hide={this.state.hidePremiumBar}
                            subscriptionMetadata={subscriptionMetadata}
                            intl={intl}
                        />}
                    </React.Fragment>
                }
                {PreviewView}
            </div>
        );
    }
}
