import React from "react";
import { Msg } from "../../../../view/intl/index";
import { SecondaryButton } from "../../../../view/common/Button/index";
import styles from "../ProductSelector/components/ProductSelector.css";
import { IState } from "./reducer";
import { CLEAR_ALL_CATEGORIES, CLEAR_ALL_PRODUCTS } from "./actions";

type Props = {
    state: IState;
    dispatch: React.Dispatch<any>;
};

const DialogFooterContent = ({ state, dispatch }: Props) => {
    const children: React.JSX.Element[] = [];
    const isCategoriesFeatured = state.categories.length;
    let countOfProducts = 0;
    if (isCategoriesFeatured) {
        state.categories.forEach(categoryId => {
            countOfProducts += state.allCategoriesResponse.filter(c => c.id === categoryId).reduce((acc, c) => acc + c.count, 0);
        });
    } else {
        countOfProducts = state.products.length;
    }
    if (countOfProducts) {
        children.push(
            <SecondaryButton
                className={styles.dialogFooterDeselectAllBtn}
                onClick={() => {
                    dispatch({
                        type: CLEAR_ALL_PRODUCTS
                    });
                    dispatch({
                        type: CLEAR_ALL_CATEGORIES
                    });
                }}
                isIntl
                key={1}
            >
                {`msg: component.webshop.featuredProducts.deselectAll {Deselect all}`}
            </SecondaryButton>
        );

        children.push(
            <Msg
                k="component.webshop.featuredProducts.selectedCountInfo"
                params={{ selectedCount: countOfProducts }}
                key={2}
                className={styles.countText}
            >
                {`{selectedCount, plural, one {# product selected} other {# products selected}}`}
            </Msg>
        );
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default DialogFooterContent;
