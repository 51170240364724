import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useIntl } from "../../../../view/intl/index";
import { DEFAULT, CUSTOM, RECOMMENDED } from "../../../../constants/app";
import { OnboardingThemingHeader } from "./OnboardingThemingHeader";
import { OnboardingThemingFooter } from "./OnboardingThemingFooter";
import type { selectedColorPickerPaletteType } from "../../flowTypes";
import Scrollbar from "../../../../view/common/Scrollbar/index";
import PreviewSwitcher from "../../../Preview/View/PreviewToolbar/previewSwitcher";
import { ViewTypes } from "../../../TemplateSelector_DEPRECATED/constants";
import onboardingTopBarStyles from "../../../TopBar/view/OnboardingTopBar.css";
import styles from "./OnboardingTheming.css";
import { ThemesAccordion } from "../../../ThemesAccordion/ThemesAccordion";
import { EditColors } from "../../../AutoColorLeftPanel/screens/EditColors";
import { ACCENT_THEME_COLOR, MAIN_THEME_COLOR, WHITE_THEME_COLOR, BLACK_THEME_COLOR } from "../../../ThemeGlobalData/constants";
import { ADD_THEME_HISTORY } from "../../../AutoColorLeftPanel/actionTypes";
import { isNextGenPreviewSelector } from "../../../TemplateSelector_DEPRECATED/epics/preview/selector";
import { EditPreview } from "./EditPreview";
import { shouldShowEditPreview } from "../../domainHashUtils";
import { dynamicPreviewSectionsLoadingSelector, dynamicTemplateIsLoadingSelector } from "../../Dynamic/Epic/selectors";

export type OnboardingThemingProps = {
    selectedColorPickerPalette: selectedColorPickerPaletteType;
    changeColorPickerThemeMethodActionType: string;
    onboardingThemingFooterCustomMctaHandler: string;
    onboardingThemingFooterCustomSctaHandler: string;
    onboardingThemingFooterDefaultMctaHandler: string;
    onboardingThemingFooterDefaultSctaHandler: string;
    changeSelectedColorPaletteActionType: string;
    changeColorPickerCustomThemeColorActionType: string;
    selectedColorPickerTheme: string;
    viewType: string;
    changePreviewActionType: string;
    dispatch: Dispatch;
};
export const OnboardingTheming = (props: OnboardingThemingProps) => {
    const intl = useIntl();
    const isNextGenPreview = useSelector(isNextGenPreviewSelector);
    const sectionLoading = useSelector(dynamicPreviewSectionsLoadingSelector);
    const dynamicTemplateIsLoading = useSelector(dynamicTemplateIsLoadingSelector);
    const isLoading: boolean = sectionLoading || dynamicTemplateIsLoading;
    const {
            selectedColorPickerPalette,
            selectedColorPickerTheme,
            changeColorPickerThemeMethodActionType,
            onboardingThemingFooterCustomMctaHandler,
            onboardingThemingFooterCustomSctaHandler,
            onboardingThemingFooterDefaultMctaHandler,
            onboardingThemingFooterDefaultSctaHandler,
            changeSelectedColorPaletteActionType,
            changeColorPickerCustomThemeColorActionType,
            viewType,
            changePreviewActionType,
            dispatch,
        } = props,
        isCustomTheme = selectedColorPickerTheme === CUSTOM,
        palette = selectedColorPickerPalette.palette,
        themeColors = {
            [ACCENT_THEME_COLOR]: palette[0],
            [MAIN_THEME_COLOR]: palette[1],
            [WHITE_THEME_COLOR]: palette[2],
            [BLACK_THEME_COLOR]: palette[3]
        };
    const showQuickEdit = isNextGenPreview && viewType === ViewTypes.desktop && shouldShowEditPreview();
    return (
        <React.Fragment>
            <div className={cx(onboardingTopBarStyles.topBar, styles.topBar)}>
                <div className={cx(onboardingTopBarStyles.oneComLogoContainer, styles.oneComLogoContainer)}>
                    <div className={onboardingTopBarStyles.oneComLogo} />
                </div>
                <PreviewSwitcher
                    style={{
                        height: "33px"
                    }}
                    mobileView={viewType === ViewTypes.mobileVertical}
                    onMobilePreview={() =>
                        dispatch({
                            type: changePreviewActionType,
                            payload: ViewTypes.mobileVertical
                        })}
                    disableMobilePreview={isLoading}
                    onDesktopPreview={() =>
                        dispatch({
                            type: changePreviewActionType,
                            payload: ViewTypes.desktop
                        })}
                />
            </div>
            <div className={styles.container}>
                <div className={styles.colorPickerContainer}>
                    {
                        showQuickEdit ?
                            <div className={styles.previewAdjustments}>
                                {intl.msgJoint("msg: onboarding.theming.quickAdjustments {Quick adjustments}")}
                            </div> :
                            null
                    }
                    {!isCustomTheme && (
                        <React.Fragment>
                            <OnboardingThemingHeader
                                selectedColorPickerTheme={selectedColorPickerTheme}
                                selectedColorPickerPalette={selectedColorPickerPalette}
                                changeColorPickerThemeMethodActionType={changeColorPickerThemeMethodActionType}
                                intl={intl}
                            />
                            {
                                !isNextGenPreview && <div className={styles.colorPickerOnboardingThemingSeparaterLine} />
                            }
                        </React.Fragment>
                    )}
                    <Scrollbar
                        height="100%"
                        theme={{ trackVertical: styles.showScrollBar }}
                    >
                        {isCustomTheme ? (
                            <div
                                style={{
                                    marginLeft: "-25px"
                                }}
                            >
                                <EditColors
                                    disabled={isLoading}
                                    themeColors={themeColors}
                                    themeSingleColorChangeAction={changeColorPickerCustomThemeColorActionType}
                                    intl={intl}
                                    dispatch={dispatch}
                                />
                            </div>
                        ) : (
                            <ThemesAccordion
                                disabled={isLoading}
                                selectedColorPickerTheme={selectedColorPickerTheme}
                                changeColorPickerThemeMethodActionType={changeColorPickerThemeMethodActionType}
                                currentPalette={selectedColorPickerPalette.palette}
                                onPaletteSelected={(selectedPalette, key) => {
                                    dispatch({
                                        type: changeSelectedColorPaletteActionType,
                                        payload: {
                                            type: key === 'concept' ? RECOMMENDED : DEFAULT,
                                            palette: selectedPalette
                                        }
                                    });
                                    dispatch({
                                        type: ADD_THEME_HISTORY,
                                        payload: selectedPalette
                                    });
                                }}
                            />
                        )}
                    </Scrollbar>
                    {
                        showQuickEdit ? (
                            <React.Fragment>
                                <div className={styles.editPreviewSeparaterLine} />
                                <EditPreview />
                            </React.Fragment>
                        ) : null
                    }
                </div>
                <div className={styles.onboardingFooterContainer}>
                    <div className={styles.colorPickerOnboardingThemingSeparaterLine} />
                    <OnboardingThemingFooter
                        selectedColorPickerTheme={selectedColorPickerTheme}
                        onboardingThemingFooterCustomMctaHandler={onboardingThemingFooterCustomMctaHandler}
                        onboardingThemingFooterCustomSctaHandler={onboardingThemingFooterCustomSctaHandler}
                        onboardingThemingFooterDefaultMctaHandler={onboardingThemingFooterDefaultMctaHandler}
                        onboardingThemingFooterDefaultSctaHandler={onboardingThemingFooterDefaultSctaHandler}
                        currentPalette={selectedColorPickerPalette.palette}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
