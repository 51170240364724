import { getRepositorySiteDataApiAction } from '../../redux/middleware/api/commonApiActions';
import type { DataDesignT } from "../../../dal/model/DataDesign";
import type { ThemeColorDataType } from "../ThemeGlobalData/flowTypes";

// TODO: Cleanup of unnecessary actions

export const
    TEMPLATE_SELECTOR_DID_MOUNT = 'TEMPLATE_SELECTOR_DID_MOUNT',
    FILTER_TEMPLATE_LIST = 'FILTER_TEMPLATE_LIST',
    FETCH_REPOSITORY_TEMPLATE_RECORDS = 'FETCH_REPOSITORY_TEMPLATE_RECORDS',
    FETCH_MODIFIED_TEMPLATE_RECORDS = 'FETCH_MODIFIED_TEMPLATE_RECORDS',
    LOAD_TEMPLATE_SELECTOR = 'LOAD_TEMPLATE_SELECTOR',
    LOAD_TEMPLATE_SELECTOR_FOR_ONBOARDING = 'LOAD_TEMPLATE_SELECTOR_FOR_ONBOARDING',
    LOAD_TEMPLATE_SELECTOR_CLICK = 'LOAD_TEMPLATE_SELECTOR_CLICK',
    TEMPLATE_SELECTOR_SHOW_PREVIEW = 'TEMPLATE_SELECTOR_SHOW_PREVIEW',
    PRE_SELECTED_TEMPLATE_PREVIEW_CLICKED = 'PRE_SELECTED_TEMPLATE_PREVIEW_CLICKED',
    TEMPLATE_SELECTOR_CALCULATE_PREVIEW = 'TEMPLATE_SELECTOR_CALCULATE_PREVIEW',
    HIDE_TEMPLATE_LIST = 'HIDE_TEMPLATE_LIST',
    TEMPLATE_SELECTOR_SEARCHED = 'TEMPLATE_SELECTOR_SEARCHED',
    IMPORT_FAILED_INFO_DIALOG_CLOSED = 'IMPORT_FAILED_INFO_DIALOG_CLOSED',
    TEMPLATE_SELECTOR_MOBILE_ORIENTATION_TOGGLE = 'TEMPLATE_SELECTOR_MOBILE_ORIENTATION_TOGGLE',
    BACK_TO_TEMPLATE_SELECTOR = 'BACK_TO_TEMPLATE_SELECTOR',
    CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE = 'CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE',
    TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK = 'TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK',
    PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE = 'PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE',
    TEMPLATE_SELECTOR_IMPORT_TEMPLATE = 'TEMPLATE_SELECTOR_IMPORT_TEMPLATE',
    COPY_REPOSITORY_TEMPLATE_REQUEST = 'COPY_REPOSITORY_TEMPLATE_REQUEST',
    COPY_REPOSITORY_TEMPLATE_SUCCESS = 'COPY_REPOSITORY_TEMPLATE_SUCCESS',
    COPY_REPOSITORY_TEMPLATE_FAILURE = 'COPY_REPOSITORY_TEMPLATE_FAILURE',
    PUT_NEW_STYLESHEET_REQUEST = 'PUT_NEW_STYLESHEET_REQUEST',
    PUT_NEW_STYLESHEET_SUCCESS = 'PUT_NEW_STYLESHEET_SUCCESS',
    PUT_NEW_STYLESHEET_FAILURE = 'PUT_NEW_STYLESHEET_FAILURE',
    PUT_NEW_TEMPLATE_REQUEST = 'PUT_NEW_TEMPLATE_REQUEST',
    PUT_NEW_TEMPLATE_SUCCESS = 'PUT_NEW_TEMPLATE_SUCCESS',
    PUT_NEW_TEMPLATE_FAILURE = 'PUT_NEW_TEMPLATE_FAILURE',
    TEMPLATE_IMPORT_COMPLETE = 'TEMPLATE_IMPORT_COMPLETE',

    POST_DYNAMIC_TEMPLATE_REQUEST = 'POST_DYNAMIC_TEMPLATE_REQUEST',
    POST_DYNAMIC_TEMPLATE_SUCCESS = 'POST_DYNAMIC_TEMPLATE_SUCCESS',
    POST_DYNAMIC_TEMPLATE_FAILURE = 'POST_DYNAMIC_TEMPLATE_FAILURE',

    GET_DESIGN_PAGE_REQUEST = 'GET_DESIGN_PAGE_REQUEST',
    GET_DESIGN_PAGE_SUCCESS = 'GET_DESIGN_PAGE_SUCCESS',
    GET_DESIGN_PAGE_FAILURE = 'GET_DESIGN_PAGE_FAILURE',

    GET_DESIGN_SITE_DATA_REQUEST = 'GET_DESIGN_SITE_DATA_REQUEST',
    GET_DESIGN_SITE_DATA_SUCCESS = 'GET_DESIGN_SITE_DATA_SUCCESS',
    GET_DESIGN_SITE_DATA_FAILURE = 'GET_DESIGN_SITE_DATA_FAILURE',

    GET_DESIGN_SITE_SETTINGS_REQUEST = 'GET_DESIGN_SITE_SETTINGS_REQUEST',
    GET_DESIGN_SITE_SETTINGS_SUCCESS = 'GET_DESIGN_SITE_SETTINGS_SUCCESS',
    GET_DESIGN_SITE_SETTINGS_FAILURE = 'GET_DESIGN_SITE_SETTINGS_FAILURE',

    GET_DESIGN_LOCALIZATIONS_REQUEST = 'GET_DESIGN_LOCALIZATIONS_REQUEST',
    GET_DESIGN_LOCALIZATIONS_SUCCESS = 'GET_DESIGN_LOCALIZATIONS_SUCCESS',
    GET_DESIGN_LOCALIZATIONS_FAILURE = 'GET_DESIGN_LOCALIZATIONS_FAILURE',

    TEMPLATE_LIST_LOCALE_SELECTED = 'TEMPLATE_LIST_LOCALE_SELECTED',
    REFRESH_TEMPLATE_PREVIEW = 'REFRESH_TEMPLATE_PREVIEW',
    TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED = 'TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED',
    RETURN_TO_ONBOARDING = 'RETURN_TO_ONBOARDING',
    CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE = 'CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE',
    CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE = 'CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE',
    ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE = 'ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE',
    ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE = 'ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE',
    CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE = 'CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE',
    CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE = 'CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE',
    TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE_ADD_TEMPLATE = 'TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE_ADD_TEMPLATE',
    CHANGE_THEMING_TEMPLATE_PREVIEW_MODE = 'CHANGE_THEMING_TEMPLATE_PREVIEW_MODE',

    TRACK_TEMPLATE_PREVIEW_EVENT = 'TRACK_TEMPLATE_PREVIEW_EVENT',

    DYNAMIC_TEMPLATE_SHOW_PREVIEW = 'DYNAMIC_TEMPLATE_SHOW_PREVIEW',
    DYNAMIC_TEMPLATE_SHOW_SELECTED_PAGE_PREVIEW = "DYNAMIC_TEMPLATE_SHOW_SELECTED_PAGE_PREVIEW",
    DYNAMIC_TEMPLATE_SHOW_PREVIEW_INIT_FAILED = 'DYNAMIC_TEMPLATE_SHOW_PREVIEW_INIT_FAILED',
    DYNAMIC_TEMPLATE_EXIT_ONBOARDING = 'DYNAMIC_TEMPLATE_EXIT_ONBOARDING',
    DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK = 'DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK',
    DYNAMIC_TEMPLATE_IMPORT_TEMPLATE = 'DYNAMIC_TEMPLATE_IMPORT_TEMPLATE',
    DYNAMIC_TEMPLATE_MULTI_PAGE_IMPORT = 'DYNAMIC_TEMPLATE_MULTI_PAGE_IMPORT',
    DYNAMIC_TEMPLATE_SINGLE_PAGE_IMPORT = 'DYNAMIC_TEMPLATE_SINGLE_PAGE_IMPORT',
    DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA = 'DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA',
    DYNAMIC_TEMPLATE_AFTER_PAGE_DATA_UPDATE = 'DYNAMIC_TEMPLATE_AFTER_PAGE_DATA_UPDATE';

export const
    returnToOnboardingAction = () => ({
        type: RETURN_TO_ONBOARDING
    });

export const
    LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_REQUEST_ACTION = 'LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_REQUEST_ACTION',
    LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION = 'LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION',
    LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_FAILURE_ACTION = 'LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_FAILURE_ACTION',
    loadTemplateSelectorRepositorySiteApiAction = () => getRepositorySiteDataApiAction({
        types: [
            LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_REQUEST_ACTION,
            LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION,
            LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_FAILURE_ACTION
        ]
    });

export const clickTemplateSelectorImport = (design: DataDesignT, themeColors: ThemeColorDataType) => ({
    type: TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK,
    payload: { ...design, themeColors },
});
