import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as AppActions from '../../../App/actionTypes';
import { CATEGORY, TEMPLATE_FILTER } from '../../constants';
import { closeDialog } from "../../../App/actionCreators/index";
import { type TemplateEpicStateType, type TemplateFilterType } from "../../flowTypes";
import * as Actions from '../../actionTypes';
import { UNSAVED_CHANGES_CHECKED } from "../../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";
import loadTemplateSelector from "../../actionCreators/loadTemplateSelector";
import { templateSelectorTemplateLocaleEpic } from '../templateSelectorTemplateLocaleEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { siteDataValueActionType } from '../../../App/epics/siteData/valueActionType';
import { getDefultLocaleIdNormalized } from "../../../Locale/getDefaultLocale";
import { RedirectReceiverVAT } from "../../../App/epics/redirectReceiverEpic";
import { filterTemplates, filterTemplatesByKeyword } from "./utils";

const DEFAULT_LOCALE_NORMALIZED = getDefultLocaleIdNormalized();

export const defaultState: TemplateEpicStateType = {
    searchText: '',
    show: false,
    allTemplates: [],
    templates: [],
    selectedTemplate: '',
    isFirstVisit: false,
    loading: false,
    filter: 'all',
    category: 'standard',
    importingTemplate: false,
    fromTemplateSelector: false,
    locale: DEFAULT_LOCALE_NORMALIZED,
    searched: false,
    filtered: false,
    onePageDesignMap: {}
};

const requestFailureLoadingUpdaters = [
    Actions.POST_DYNAMIC_TEMPLATE_FAILURE
].map(type => ({
    conditions: [type],
    reducer: ({ state, scope }) => {
        return {
            state: {
                ...state,
                loading: false,
            },
            scope
        };
    }
}))

const requestFailureUpdaters = [
    Actions.LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_FAILURE_ACTION,
    AppActions.REPOSITORY_PAGES_LOAD_FAILURE,
    AppActions.REPOSITORY_TEMPLATE_DATA_LOAD_FAILURE,
].map(type => ({
    conditions: [type],
    reducer: ({ state, scope }) => {
        return {
            state: {
                ...state,
                loading: false,
                show: false
            },
            scope
        };
    }
}));

export default makeEpic({
    defaultState,
    defaultScope: {
        browsingTemplates: false
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(templateSelectorTemplateLocaleEpic.valueActionType),
                Actions.LOAD_TEMPLATE_SELECTOR_FOR_ONBOARDING
            ],
            reducer: ({ values: [locale, templateFilterObject = {}], state, scope }) => {
                const {
                        category = CATEGORY.standard,
                        filter = TEMPLATE_FILTER.all
                    }: TemplateFilterType = templateFilterObject,
                    // @ts-ignore
                    { isFirstVisit = true } = templateFilterObject;

                let multipleActionsToDispatch = [
                    { type: Actions.FETCH_REPOSITORY_TEMPLATE_RECORDS },
                    Actions.loadTemplateSelectorRepositorySiteApiAction(),
                ];

                return {
                    state: {
                        ...state,
                        category,
                        filter,
                        searched: false,
                        filtered: false,
                        loading: true,
                        show: true,
                        isFirstVisit,
                        fromTemplateSelector: true,
                        locale
                    },
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [RedirectReceiverVAT],
            reducer: ({ values: [sessionData], state, scope }): any => {
                const taskId = sessionData && sessionData.key;

                if (sessionData && typeof taskId === "string" && taskId === Actions.LOAD_TEMPLATE_SELECTOR) {
                    return {
                        state,
                        scope,
                        actionToDispatch: loadTemplateSelector(sessionData.payload)
                    };
                }

                return {
                    state,
                    scope
                };
            },
        },
        {
            conditions: [
                receiveOnly(templateSelectorTemplateLocaleEpic.valueActionType),
                Actions.LOAD_TEMPLATE_SELECTOR
            ],
            reducer: ({ values: [locale, templateFilterObject = {}], state, scope }) => {
                const {
                        category = CATEGORY.standard,
                        filter = TEMPLATE_FILTER.all
                    }: TemplateFilterType = templateFilterObject,
                    // @ts-ignore
                    { isFirstVisit = false } = templateFilterObject;

                let multipleActionsToDispatch = [
                    { type: Actions.FETCH_REPOSITORY_TEMPLATE_RECORDS },
                    Actions.loadTemplateSelectorRepositorySiteApiAction(),
                    closeDialog()
                ];

                return {
                    state: {
                        ...state,
                        category,
                        filter,
                        loading: true,
                        show: true,
                        isFirstVisit,
                        fromTemplateSelector: true,
                        locale
                    },
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [Actions.LOAD_TEMPLATE_SELECTOR_CLICK],
            reducer: ({ values: [{ isFirstVisit }], state }) => ({
                state: {
                    ...state,
                    isFirstVisit
                },
                scope: {
                    browsingTemplates: true
                },
                actionToDispatch: checkUnsavedChangesAction()
            })
        },
        {
            conditions: [UNSAVED_CHANGES_CHECKED],
            reducer: ({ state, scope }) => {
                if (scope.browsingTemplates) {
                    return {
                        state,
                        scope: {
                            browsingTemplates: false
                        },
                        actionToDispatch: loadTemplateSelector({ isFirstVisit: false, source: 'Add pages dialog' })
                    };
                }
                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                templateSelectorTemplateLocaleEpic.valueActionType,
                Actions.LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION,
                Actions.FETCH_REPOSITORY_TEMPLATE_RECORDS,
            ],
            reducer: ({ values: [locale, designsData], state, scope, sourceAction }) => {
                if (!state.fromTemplateSelector && sourceAction.type !== Actions.TEMPLATE_LIST_LOCALE_SELECTED) {
                    return { state, scope };
                }
                return {
                    state: filterTemplates({
                        ...state,
                        designsData,
                        loading: false,
                        fromTemplateSelector: false,
                        locale
                    }),
                    scope,
                };
            }
        },
        {
            conditions: [
                Actions.FILTER_TEMPLATE_LIST
            ],
            reducer: ({ values: [filter = TEMPLATE_FILTER.all], state, scope }) => {
                return {
                    state: filterTemplates({ ...state, filter, filtered: true }),
                    scope
                };
            }
        },
        {
            conditions: [
                Actions.TEMPLATE_SELECTOR_SEARCHED
            ],
            reducer: ({ values: [keyword], state, scope }) => {
                let filter = state.filter;
                if (keyword) {
                    filter = TEMPLATE_FILTER.all;
                }
                return {
                    state: {
                        ...state,
                        searchText: keyword,
                        filter,
                        filteredDesigns: filterTemplatesByKeyword({ ...state, keyword }),
                        searched: true,
                    },
                    scope
                };
            }
        },
        {
            conditions: [Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE],
            reducer: ({ state, scope }) => {
                return { state: { ...state, loading: true, importingTemplate: true }, scope };
            }
        },
        {
            conditions: [Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE],
            reducer: ({ state, scope }) => {
                return { state: { ...state, loading: true, importingTemplate: true }, scope };
            }
        },
        {
            conditions: [
                Actions.HIDE_TEMPLATE_LIST
            ],
            reducer: ({ scope }) => {
                return ({ state: { ...defaultState }, scope });
            }
        },
        {
            conditions: [
                Actions.IMPORT_FAILED_INFO_DIALOG_CLOSED
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, loading: false, importingTemplate: false },
                    actionToDispatch: closeDialog(),
                    scope
                };
            }
        },
        ...requestFailureUpdaters,
        ...requestFailureLoadingUpdaters,
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                Actions.TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED
            ],
            reducer: ({ state, scope, values: [site] }) => {
                const actionToDispatch =
                    site.getAllPageRefs().length
                        ? { type: Actions.HIDE_TEMPLATE_LIST }
                        : Actions.returnToOnboardingAction();
                return {
                    state,
                    scope,
                    actionToDispatch
                };
            }
        }
    ]
});
