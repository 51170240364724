import {
    ADD_CATEGORY,
    ADD_PRODUCT,
    CLEAR_ALL_CATEGORIES,
    CLEAR_ALL_PRODUCTS,
    REMOVE_CATEGORY,
    REMOVE_PRODUCT,
    SET_ALL_CATEGORIES_RESPONSE,
    SET_IS_DIRTY
} from "./actions";

export interface ICategory {
    id: string;
    count: number;
    name: string;
}

export interface IState {
    categories: string[];
    products: string[];
    isDirty:boolean;
    allCategoriesResponse: {
        id: string,
        name: string,
        count: number
    }[]
}

function reducer(state: IState, action): IState {
    switch (action.type) {
        case ADD_CATEGORY:
            return { ...state, categories: [...state.categories, action.payload] };
        case REMOVE_CATEGORY:
            return { ...state, categories: state.categories.filter(category => category !== action.payload) };
        case CLEAR_ALL_CATEGORIES:
            return { ...state, categories: [] };
        case ADD_PRODUCT:
            return { ...state, products: [...state.products, action.payload] };
        case REMOVE_PRODUCT:
            return { ...state, products: state.products.filter(product => product !== action.payload) };
        case CLEAR_ALL_PRODUCTS:
            return { ...state, products: [] };
        case SET_IS_DIRTY:
            return { ...state, isDirty: true };
        case SET_ALL_CATEGORIES_RESPONSE:
            return { ...state, allCategoriesResponse: action.payload };
        default:
            return state;
    }
}

export default reducer;
