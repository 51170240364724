import React from "react";
import CheckBoxSlider from "../../../../../view/common/CheckBox/CheckBoxSlider";
import { IState } from "../../SelectorDialog/reducer";
import { ADD_CATEGORY, CLEAR_ALL_PRODUCTS, REMOVE_CATEGORY } from "../../SelectorDialog/actions";

interface IProps {
    state: IState;
    dispatch: React.Dispatch<any>;
    selectedCategoryId: string;
}

const CategoryToggle = ({ state, dispatch, selectedCategoryId }: IProps) => {
    return (
        <CheckBoxSlider
            isChecked={state.categories.includes(selectedCategoryId)}
            onClick={() => {
                if (state.categories.includes(selectedCategoryId)) {
                    dispatch({
                        type: REMOVE_CATEGORY,
                        payload: selectedCategoryId
                    });
                } else {
                    dispatch({
                        type: ADD_CATEGORY,
                        payload: selectedCategoryId
                    });
                    dispatch({ type: CLEAR_ALL_PRODUCTS });
                }
            }}
        />
    );
};

export default CategoryToggle;
