/* @flow */

const { WSBSubToPartnerConfigType, SubscriptionType } = require('./constants');

// Below list of components should match the list of components in the subscriptions inside
// the common.cjson
const ConfigComponentList = {
    "VIDEO_FILE": "VIDEO_FILE",
    "HD_IMAGES": "HD_IMAGES",
    "ONLINE_SHOP": "ONLINE_SHOP",
    "GOOGLE_REVIEWS": "GOOGLE_REVIEWS",
    "WIDGETS_BOOKINGS": "WIDGETS_BOOKINGS",
    "GOOGLE_MY_BUSINESS": "GOOGLE_MY_BUSINESS",
    "GENERIC_WIDGETS": "GENERIC_WIDGETS",
    "MULTIPAGE_ONBOARDING": "MULTIPAGE_ONBOARDING",
};

const matchSubscriptionType = (currentSubscription) => (
    currentSubscription === SubscriptionType.DEMO ? SubscriptionType.TRIAL : currentSubscription
);
const getFeaturesConfig = (subscriptionConfig, partnerName, currentSubscription) => {
    const wsbSubToPartnerConfigSubMap = WSBSubToPartnerConfigType[partnerName];
    if (!wsbSubToPartnerConfigSubMap) {
        throw new Error("Invalid subscription feature management config");
    }
    const partnerSubsConfig = wsbSubToPartnerConfigSubMap[currentSubscription];
    // TODO: Skip logs for test environment
    // console.log(`FEATUREMANAGER: currentSubscription: ${currentSubscription} | partnerSubsConfig: ${partnerSubsConfig}`);
    return subscriptionConfig[partnerSubsConfig];
};

class SubscriptionFeatureManager {
    /*:: #_partnerName: string; */
    /*:: #_subscriptionConfig: Object; */
    /*:: #_currentSubscription: string; */
    /*:: #_featuresConfig: Object; */

    constructor(subscriptionConfig/*: Object */ = {}, partnerName/*: string */ = '') {
        this./*::#*/_partnerName = partnerName;
        this./*::#*/_subscriptionConfig = subscriptionConfig;
        this./*::#*/_currentSubscription = SubscriptionType.UNKNOWN;
        this./*::#*/_featuresConfig = {};
    }

    getCurrentSubscription() {
        return this./*::#*/_currentSubscription;
    }

    setCurrentSubscription(subs/*: Object */) {
        this./*::#*/_currentSubscription = matchSubscriptionType(subs);
        this./*::#*/_featuresConfig = getFeaturesConfig(
            this./*::#*/_subscriptionConfig, this./*::#*/_partnerName, this./*::#*/_currentSubscription
        );
    }

    isHdImagesAllowed() {
        return this./*::#*/_featuresConfig[ConfigComponentList.HD_IMAGES];
    }

    isVideoFileCmpAllowed() {
        return this./*::#*/_featuresConfig[ConfigComponentList.VIDEO_FILE];
    }

    isOnlineShopCmpsAllowed() {
        /* This includes all type of components related to webshop */
        const onlineShop = this./*::#*/_featuresConfig[ConfigComponentList.ONLINE_SHOP];
        return onlineShop && onlineShop.enabled;
    }

    isGoogleReviewsAllowed() {
        const googleReviews = this./*::#*/_featuresConfig[ConfigComponentList.GOOGLE_REVIEWS];
        return googleReviews && googleReviews.enabled;
    }

    isBoookingsWidgetAllowed() {
        const bookingsWidget = this./*::#*/_featuresConfig[ConfigComponentList.WIDGETS_BOOKINGS];
        return bookingsWidget && bookingsWidget.enabled;
    }

    isGoogleMyBusinessAllowed() {
        const googleMyBusiness = this./*::#*/_featuresConfig[ConfigComponentList.GOOGLE_MY_BUSINESS];
        return googleMyBusiness && googleMyBusiness.enabled;
    }

    isGenericWidgetAllowed() {
        const genericWidgets = this./*::#*/_featuresConfig[ConfigComponentList.GENERIC_WIDGETS];
        return genericWidgets && genericWidgets.enabled;
    }

    isMultipageOnboardngAllowed() {
        const multipageOnboarding = this./*::#*/_featuresConfig[ConfigComponentList.MULTIPAGE_ONBOARDING];
        return multipageOnboarding && multipageOnboarding.enabled;
    }
}

module.exports = {
    SubscriptionFeatureManager,
};
