import {
    makePathPayloadToSetterReducer,
    makePayloadToSetterReducer
} from "../../../../redux/makeReducer/index";
import * as actionTypes from "../actionTypes";
import { setToPath, toggleValue } from '../../../../utils/ramdaEx';
import opacityToAlpha from '../../Background/utils/opacityToAlpha';
import p from '../../../../utils/pipePath';
import type { FeaturedProductsComponent } from "../flowTypes";
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from "../../../PropertiesPanel/view/MobileView/mobileDownReducer";
import stylesheetsValueActionType from "../../../Workspace/epics/stylesheets/valueActionType";
import { getButtonStyleId } from "../../WebShop/utils";

const makeColorReducer = prop => makePathPayloadToSetterReducer([prop], ['color']);
const makeThemeColorReducer
    = (prop: string) => (component, { payload: { themeColor } }) => ({ ...component, [prop]: themeColor });

const updateComponentPropertyReducer = (propertyName) => {
    return (component: FeaturedProductsComponent, { payload }: any) => {
        if (component.id === payload.componentId) {
            return {
                ...component,
                [propertyName]: payload[propertyName]
            };
        }
        return {
            ...component
        };
    };
};

export const actionsReducers = {
    [actionTypes.FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY]: updateComponentPropertyReducer('products'),
    [actionTypes.FEATURED_PRODUCTS_CATGORIES_CHANGED]: updateComponentPropertyReducer('categories'),
    [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
    [actionTypes.FEATURED_PRODUCTS_FONT_FAMILY_CHANGED]: makePayloadToSetterReducer('font'),
    [actionTypes.FEATURED_PRODUCTS_TEXT_COLOR_CHANGED]: makeColorReducer('fontColor'),
    [actionTypes.FEATURED_PRODUCTS_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('fontColorTheme'),
    [actionTypes.FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED]: makeColorReducer('labelTextColor'),
    [actionTypes.FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('labelTextColorTheme'),
    [actionTypes.FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED]: makeColorReducer('labelBgColor'),
    [actionTypes.FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('labelBgColorTheme'),
    [actionTypes.FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED]: makeColorReducer('focusColor'),
    [actionTypes.FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('focusColorTheme'),
    [actionTypes.FEATURED_PRODUCTS_HOVER_COLOR_CHANGED]: makeColorReducer('hoverColor'),
    [actionTypes.FEATURED_PRODUCTS_HOVER_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('hoverColorTheme'),
    [actionTypes.FEATURED_PRODUCTS_HOVER_OPACITY_CHANGED]: (component: FeaturedProductsComponent, { payload }: any) => {
        return {
            ...component,
            temporaryHoverOpacity: 0,
            hoverColor: setToPath(p(4), opacityToAlpha(payload), [...component.hoverColor])
        };
    },
    [actionTypes.FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED]: makePayloadToSetterReducer('imageRatio'),
    [actionTypes.FEATURED_PRODUCTS_LABEL_POSITION_CHANGED]: makePayloadToSetterReducer('labelPosition'),
    [actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_PAGE_CHANGED]: makePayloadToSetterReducer('productsPerPage'),
    [actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_ROW_CHANGED]: makePayloadToSetterReducer('productsPerRow'),
    [actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED]: makePayloadToSetterReducer('productsPerRowInMobile'),
    [actionTypes.FEATURED_PRODUCTS_SPACING_ITEMS_IN_MOBILE_CHANGED]: makePayloadToSetterReducer('spacingItemsInMobile'),
    [actionTypes.FEATURED_PRODUCTS_CROP_IMAGES_CHANGED]: toggleValue(['cropImages']),
    [actionTypes.FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED]: makePayloadToSetterReducer('productListStyle'),
    [actionTypes.FEATURED_PRODUCTS_UPDATE_HEIGHT]: updateComponentPropertyReducer('height'),
    [actionTypes.FEATURED_PRODUCTS_UPDATE_WIDTH]: updateComponentPropertyReducer('width'),
    [actionTypes.FEATURED_PRODUCTS_UPDATE_PRODUCTS_PER_ROW]: updateComponentPropertyReducer('productsPerRow'),
    [actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_SHOW_BUTTON_EXISTS]: makePayloadToSetterReducer('viewDetailsShowButton'),
    [actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_TEXT]: makePayloadToSetterReducer('viewDetailsButtonText'),
    [actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE]:
         makePathPayloadToSetterReducer(['viewDetailsButtonId'], ['stylesheetId']),
    [actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE_THEME]:
        makePayloadToSetterReducer('viewDetailsButtonThemeSelected'),
    [actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION]: (component: FeaturedProductsComponent, { payload }: any) => {
        return {
            ...component,
            viewDetailsButtonPosition: payload.value,
            newHeight: payload.height,
            width: payload.width
        };
    },
    [stylesheetsValueActionType]: (component, { payload: globalStyles }) => {
        const viewDetailsButtonId = getButtonStyleId(component.viewDetailsButtonId, globalStyles);
        if (viewDetailsButtonId === component.viewDetailsButtonId) {
            return component;
        }
        return {
            ...component,
            viewDetailsButtonId
        };
    },
    [actionTypes.FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS]: updateComponentPropertyReducer('isWebshopSetupDone'),
    [actionTypes.FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT]: updateComponentPropertyReducer('newHeight'),
    [actionTypes.FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED]: toggleValue(['enableBuyNowButton']),
    [actionTypes.FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE_THEME]:
        makePayloadToSetterReducer('buyNowButtonThemeSelected'),
    [actionTypes.FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE]:
        makePathPayloadToSetterReducer(['buyNowButtonId'], ['stylesheetId']),
    [actionTypes.FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_TEXT]: makePayloadToSetterReducer('buyNowButtonText'),

    [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED]: makeColorReducer("promoRibbonBgColor"),
    [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer("promoRibbonBgColorTheme"),
    [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED]: makeColorReducer("promoRibbonTextColor"),
    [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer("promoRibbonTextColorTheme")
};
