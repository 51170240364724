import * as R from 'ramda';
import queryMap from "../reducer/queryMap.json";
import { makePlainMappers, applyMappers } from '../../../../../dal/pageMapAdapter/utils';
import * as colorMapper from '../../../../mappers/color';
import fontMap from './fontMap.json';
import type { QueryData, FeaturedProductsViewProps } from '../flowTypes';
import { getFontName, isGoogleFont } from "../../../presentational/AddGoogleFont/utils";
import { GOOGLE_PREFIX } from "../../../presentational/AddGoogleFont/constants";
import { CommonMapperKeys } from "../constants";
import { getWidgetId } from '../utils';
import { BODY_BACKGROUND_CLASS_NAME } from '../../../Preview/View/constants';
import { getColorsForWebshopPublishScript } from '../../WebShop/utils';

const plainMapper = makePlainMappers({ ...queryMap, ...CommonMapperKeys });

function isGoogleFontWithoutPrefix(font, siteFonts) {
    return siteFonts.indexOf(font) > -1;
}

function getPreviewFontName(font, siteFonts) {
    if (isGoogleFont(font)) {
        return font;
    }
    if (isGoogleFontWithoutPrefix(font, siteFonts)) {
        return GOOGLE_PREFIX + font;
    }

    return fontMap[font] || font;
}

function makeColorFontMapper(siteFonts) {
    return (component) => {
        return R.filter(v => v, {
            ...[
                'focusColor',
                'labelBgColor',
                'labelTextColor',
                'fontColor',
                'promoRibbonBgColor',
                'promoRibbonTextColor',
            ].reduce((d, prop) => {
                if (!component[prop]) {
                    return d;
                }
                const map = d;
                map[prop] = colorMapper.toHex(component[prop]).replace('#', '');
                return map;
            }, {}),
            ...{
                font: getPreviewFontName(component.font, siteFonts),
                hoverColor: component.hoverColor ? colorMapper.toCss(component.hoverColor) : null,
                labelPosition: component.labelPosition
            }
        });
    };
}

export default function getQueryData(
    viewProps: FeaturedProductsViewProps
): QueryData {
    const
        {
            component,
            globalStyles,
            themeSettingsData: {
                autoColorMode
            },
            selectedParentTheme,
            viewDetailsButtonClassname,
            viewDetailsButtonStyle,
            buttonClassname,
            linkClassName,
            linkStyle,
            locale,
            isMVEFocus,
            shopComponentExists,
            backgroundColor,
            buyNowButtonClassName,
        } = viewProps;

    let componentDataForTheme;
    if (autoColorMode) {
        componentDataForTheme = {
            ...component,
            ...getColorsForWebshopPublishScript({
                autoColorMode,
                globalStyles,
                selectedParentTheme,
                component
            })
        };
    }

    const
        getComponentWithThemeData = () => (autoColorMode ? componentDataForTheme : component),
        {
            id,
            imageRatio,
            cropImages,
            productsPerRow,
            productsPerRowInMobile,
            categories,
            labelPosition,
            productListStyle,
            spacingItemsInMobile,
            font,
            fontColor,
            focusColor,
            hoverColor,
            labelBgColor,
            labelTextColor,
            height,
            viewDetailsShowButton,
            viewDetailsButtonText,
            viewDetailsButtonPosition,
            isSingleProduct,
            isWebshopSetupDone,
            enableBuyNowButton,
            buyNowButtonText,
            promoRibbonBgColor,
            promoRibbonTextColor
        } = getComponentWithThemeData();

    const mappersData = {
        imageRatio,
        buttonClassname,
        cropImages,
        productsPerRow,
        productsPerRowInMobile,
        categories,
        labelPosition,
        productListStyle,
        spacingItemsInMobile,
        linkClassName,
        linkStyle,
        font,
        fontColor,
        focusColor,
        hoverColor,
        labelBgColor,
        labelTextColor,
        locale,
        forceDesktopView: !viewProps.isMVEFocus && !viewProps.mobilePreview,
        height,
        timestamp: viewProps.timestamp || 0,
        widgetId: getWidgetId(id),
        isMVEFocus,
        shopComponentExists,
        bodyBackground: BODY_BACKGROUND_CLASS_NAME,
        isWorkspace: !!viewProps.isWorkspace,
        viewDetailsShowButton,
        viewDetailsButtonText,
        viewDetailsButtonStyle,
        viewDetailsButtonClassname,
        viewDetailsButtonPosition,
        isSingleProduct,
        isWebshopSetupDone,
        siteFonts: viewProps.siteFonts,
        autoColorMode,
        backgroundColor,
        fetchProductsByMessageEvent: true,
        enableBuyNowButton,
        buyNowButtonClassName,
        buyNowButtonText,
        promoRibbonBgColor,
        promoRibbonTextColor
    };
    const toFn = plainMapper.to;
    const colorFontMapper = makeColorFontMapper(viewProps.siteFonts.map(font => getFontName(font)));

    const queryData = applyMappers(mappersData, toFn, colorFontMapper);

    return queryData;
}
