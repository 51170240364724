import makeEpic from "../../../../../../epics/makeEpic";
import {
    LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION,
    SWITCH_TO_TEMPLATE_CONTENT_ACTION,
    SWITCH_TO_TEMPLATE_SETTINGS_ACTION,
    SWITCH_TO_TEMPLATE_STYLES_ACTION,
    LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION,
    SAVE_TEMPLATE_SELECTION_ACTION,
    SWITCH_PAGE_FROM_TEMPLATE_TAB_ACTION,
    loadTemplateTabTemplatesAction,
    setTemplateTabTemplatesAction
} from "../view/tabs/template-tab/actions";
import { showTemplateSettingsAction } from "../../../../../../redux/modules/children/workspace/actionCreators";
import { closeDialog } from "../../../../../App/actionCreators";
import { openTextGlobalStylesDialogAction } from "../../../../../presentational/GlobalstyleDialogInvoker";
import {
    type SavePageInfoActionParams,
    CANCEL_PAGE_INFO_CHANGES_ACTION,
    CLOSE_PAGE_INFO_DIALOG_ACTION,
    DONT_SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION,
    SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION,
    SWITCH_PAGE_INFO_TAB_ACTION,
    savePageInfoAction,
    NAVIGATE_PAGE_INFO_TO_SITE_SETTINGS_ACTION
} from "../actions";
import { PageInfoTab } from "../../../constants";
import { openPageTemplateSelectorErrorDialog } from "../../../../PageTemplateSelector/dialogs/errorDialog/actions";
import { receiveOnly } from "../../../../../../epics/makeCondition";
import { dialogManagerVAT } from "../../../../../DialogManager/epic/valueActionType";
import { topMostDialogStateSelector } from "../../../../../DialogManager/epic/selectors";
import { makePageInfoCheckUnsavedChangesUpdater } from "./updaters";
import { checkUnsavedChangesAction } from "../../../../../UnsavedChanges/actionCreators";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "../../../../../UnsavedChanges/actionTypes";
import { UPDATE_SITE_DATA_SAGA_AFTER_SAVE } from "../../../../actionTypes";
import { siteDataReceiveOnlyValueActionType } from "../../../../../App/epics/siteData/valueActionType";
import { openSiteSettingsDialog } from "../../../../../SiteSettings/SiteSettingsDialog/actionCreators";
import { UNSAVED_CHANGES_DIALOG_TYPE_2 } from "../unsavedChangesDialog/dialog";
import { SiteSettingsTabName } from "../../../../../SiteSettings/SiteSettingsDialog/constants";
import loadPageAction from "../../../../actionCreators/loadPageAction";

// TODO: integration tests: wbtgen/src/components/PagesTree/Tree/dialogs/PageInfoDialog/tests/pageInfoDialogTemplateTab.test.js

export const PAGE_INFO_DIALOG_VAT = 'PAGE_INFO_DIALOG_VAT';

export const pageInfoDialogEpic = makeEpic({
    defaultState: null,
    defaultScope: {
        switchingPage: false,
        unsavedChangesProceedActions: null
    },
    valueActionType: PAGE_INFO_DIALOG_VAT,
    updaters: [
        {
            /**
             * TODO:
             * - use selector
             * - test
             */
            conditions: [
                receiveOnly(dialogManagerVAT, topMostDialogStateSelector),
                SWITCH_PAGE_INFO_TAB_ACTION
            ],
            reducer: ({ state, scope, values: [pageInfoDialogState, tabId] }) => {
                const { [PageInfoTab.TEMPLATE]: { templates } } = pageInfoDialogState;

                if (tabId === PageInfoTab.TEMPLATE && !templates.length) {
                    return {
                        state,
                        scope,
                        actionToDispatch: loadTemplateTabTemplatesAction()
                    };
                }

                return { state, scope };
            }
        },
        {
            conditions: [LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION],
            reducer: ({ state, scope, values: [templates] }) => ({
                state,
                scope,
                actionToDispatch: setTemplateTabTemplatesAction(templates)
            })
        },
        {
            conditions: [SAVE_TEMPLATE_SELECTION_ACTION],
            reducer: ({ state, scope }) => ({
                state,
                scope,
                actionToDispatch: closeDialog()
            })
        },
        {
            conditions: [LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION],
            reducer: ({ state, scope }) => ({
                state,
                scope,
                actionToDispatch: openPageTemplateSelectorErrorDialog({
                    msg: 'msg: common.unableToFetchTemplates {Unable to get templates.}'
                })
            })
        },
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: CLOSE_PAGE_INFO_DIALOG_ACTION,
            proceedActions: [
                closeDialog()
            ]
        }),
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: NAVIGATE_PAGE_INFO_TO_SITE_SETTINGS_ACTION,
            proceedActions: [
                closeDialog(),
                openSiteSettingsDialog({
                    activeTabKey: SiteSettingsTabName.SEO
                })
            ],
            extraData: { unsavedChangesDialogType: UNSAVED_CHANGES_DIALOG_TYPE_2 }
        }),
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: SWITCH_TO_TEMPLATE_SETTINGS_ACTION,
            proceedActions: [
                closeDialog(),
                showTemplateSettingsAction(true/* preserveNav */)
            ]
        }),
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: SWITCH_TO_TEMPLATE_STYLES_ACTION,
            proceedActions: [
                closeDialog(),
                openTextGlobalStylesDialogAction()
            ]
        }),
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: SWITCH_TO_TEMPLATE_CONTENT_ACTION,
            proceedActions: [closeDialog()]
        }),
        makePageInfoCheckUnsavedChangesUpdater({
            sourceAction: SWITCH_PAGE_FROM_TEMPLATE_TAB_ACTION,
            proceedActions: [
                checkUnsavedChangesAction()
            ],
            extraScope: { switchingPage: true }
        }),
        {
            conditions: [
                siteDataReceiveOnlyValueActionType,
                UNSAVED_CHANGES_CHECKED
            ],
            reducer: ({ state, scope, values: [site] }) => {
                if (scope.switchingPage) {
                    const pageId = site.getPageRefById(scope.pageRefId).pageId;
                    return {
                        state,
                        scope: { ...scope, switchingPage: null },
                        actionToDispatch: loadPageAction(pageId)
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => (
                scope.switchingPage
                    ? { state, scope: { ...scope, switchingPage: null } }
                    : { state, scope }
            )
        },
        {
            conditions: [SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION],
            reducer: (
                {
                    state,
                    scope,
                    values: [pageRefId]
                }
            ) => {
                if (scope.unsavedChangesProceedActions) {
                    const savePageParams: SavePageInfoActionParams = {
                        pageRefId
                    };
                    if (scope.switchingPage) {
                        savePageParams.keepDialogOpenOnSave = true;
                    }

                    return {
                        state,
                        scope,
                        multipleActionsToDispatch: [
                            closeDialog(),
                            savePageInfoAction(savePageParams)
                        ]
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [UPDATE_SITE_DATA_SAGA_AFTER_SAVE],
            reducer: ({ state, scope }) => (
                scope.unsavedChangesProceedActions
                    ? {
                        state,
                        scope: { ...scope, unsavedChangesProceedActions: null },
                        multipleActionsToDispatch: scope.unsavedChangesProceedActions
                    }
                    : { state, scope }
            )
        },
        {
            conditions: [DONT_SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION],
            reducer: ({ state, scope }) => {
                if (scope.unsavedChangesProceedActions) {
                    return {
                        state,
                        scope: { ...scope, unsavedChangesProceedActions: null },
                        multipleActionsToDispatch: [
                            closeDialog(),
                            ...scope.unsavedChangesProceedActions
                        ]
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [CANCEL_PAGE_INFO_CHANGES_ACTION],
            reducer: ({ state, scope }) => (
                scope.unsavedChangesProceedActions
                    ? {
                        state,
                        scope: { ...scope, unsavedChangesProceedActions: null },
                        actionToDispatch: closeDialog()
                    }
                    : { state, scope }
            )
        }
    ]
});
