import * as actionTypes from '../actionTypes';
import { setDashboardSessionDefaultPage } from '../../../../../../src/dashboard/src/components/SideBar/utils';
import { navigateToLocationAction } from '../../../../redux/middleware/window/actionCreators';
import { DASHBOARD_ROUTE } from '../../../../../../server/shared/routes';
import { PRIVACY_POLICY_ITEM_ID } from '../../../../../../src/dashboard/src/components/Pages/PrivacyPolicy/constants';
import {
    UNSAVED_CHANGES_CHECKED,
    CANCEL_UNSAVED_CHANGES,
} from "../../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";

const state: any = {
    redirectToDashboard: false,
};

export const getState = () => state;
export const updateState = (redirectToDashboard: boolean): void => {
    state.redirectToDashboard = redirectToDashboard;
};

export const redirectToDashboard = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === actionTypes.MARKETING_CONSENT_GO_TO_DASHBOARD_FOR_PP) {
        // state.redirectToDashboard = true;
        updateState(true);
        store.dispatch(checkUnsavedChangesAction());
    } else if (state.redirectToDashboard === true) {
        if (action.type === CANCEL_UNSAVED_CHANGES) {
            // state.redirectToDashboard = false;
            updateState(false);
        } else if (action.type === UNSAVED_CHANGES_CHECKED) {
            // This is important
            updateState(false);
            window.skipUnsavedChangesPrompt = true;
            setDashboardSessionDefaultPage(PRIVACY_POLICY_ITEM_ID);
            store.dispatch(navigateToLocationAction(DASHBOARD_ROUTE));
        }
    }

    return next(action);
};
