import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import styles from "./OnboardingTheming.css";
import { useIntl } from "../../../../view/intl";
import CheckBox from "../../../../view/common/CheckBox/CheckBoxSlider";
import { DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED } from "../../Dynamic/Epic/actionTypes";
import { dynamicPreviewSectionsLoadingSelector, dynamicTemplateIsLoadingSelector, editPreviewSelector } from "../../Dynamic/Epic/selectors";

export const EditPreview = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const editPreview = useSelector(editPreviewSelector);
    const sectionLoading = useSelector(dynamicPreviewSectionsLoadingSelector);
    const dynamicTemplateIsLoading = useSelector(dynamicTemplateIsLoadingSelector);

    return (
        <div className={styles.editPreviewContainer}>
            <div className={styles.checkBoxContainer}>
                <div className={cx(styles.editPreviewLabel)}>
                    {intl.msgJoint("msg: onboarding.theming.quickEdit {Quick edit}")}
                </div>
                <CheckBox
                    isChecked={editPreview}
                    disabled={sectionLoading || dynamicTemplateIsLoading}
                    onClick={() => {
                        dispatch({ type: DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED, payload: !editPreview });
                    }}
                    height={24}
                    width={42}
                />
            </div>
            <div className={styles.editPreviewDescription}>
                {
                    // eslint-disable-next-line max-len
                    intl.msgJoint("msg: onboarding.theming.quickEdit.description {Generate new text before using the full editor for more changes.}")
                }
            </div>
        </div>
    );
};
