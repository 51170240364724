/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styles from './ProductSelector.css';
import CategoriesContainer from './CategoriesContainer';
import ProductsContainer from './ProductsContainer';
import { IState } from '../../SelectorDialog/reducer';
import { CLOSE_UNSAVED_CHANGES_DIALOG, OPEN_SHOP_CATEGOREIS, SHOW_UNSAVED_CHANGES_DIALOG } from '../../SelectorDialog/actionTypes';

type Props = {
    appDispatch: Dispatch,
    state: IState,
    dispatch: React.Dispatch<any>,
    hasOnlyDigitalShop: boolean
}

const messages={
    continueToCategories: 'msg: component.webshop.featuredProducts.dialog.ContinueToCategories {Continue to categories}',
    cancel: 'msg: common.cancel {Cancel}',
    warnLoosingCategoryProgress: `msg: component.webshop.featuredProducts.categoryProgress 
    {Your featured products have unsaved changes. Save them to avoid losing progress before going to your categories.}`
}

const ProductSelector = ({
    appDispatch,
    state,
    dispatch,
    hasOnlyDigitalShop
}: Props) => {
    const [products, setProducts] = useState([]);
    const [productsTotal, setProductsTotal] = useState([]);
    const [filters, setFilters] = useState({ categoryId: '', productName: '' });

    const applyFilters = (f) => {
        setFilters({ ...filters, ...f });
    };

    const setProductRecords = ({
        totalRecords,
        products: f
    }, reset = false) => {
        setProductsTotal(totalRecords);
        setProducts(reset ? f : [...products, ...f]);
    };

    const openShopCategories=() => {
        appDispatch({ type: OPEN_SHOP_CATEGOREIS })
    }

    const handleClickOnManageCatgories = () => {
        if (state.isDirty) {
            appDispatch({ type: SHOW_UNSAVED_CHANGES_DIALOG,
                payload: { params: {
                    bodyText: messages.warnLoosingCategoryProgress,
                    cancelBtnText: messages.cancel,
                    successBtnText: messages.continueToCategories,
                    onSaveClick: openShopCategories,
                    onCancelClick: () => { appDispatch({ type: CLOSE_UNSAVED_CHANGES_DIALOG }) }
                } } })
        } else {
            openShopCategories()
        }
    }

    const params = {
        filters,
        applyFilters,
        products,
        productsTotal,
        setProductRecords,
        state,
        dispatch,
        appDispatch,
        hasOnlyDigitalShop,
        selectedCategoryId: filters.categoryId,
        onManageCategoriesClick: handleClickOnManageCatgories
    };

    return (
        <div className={styles.container}>
            <CategoriesContainer
                selectedCategoryId={filters.categoryId}
                applyFilters={applyFilters}
                state={state}
                dispatch={dispatch}
                appDispatch={appDispatch}
            />
            <ProductsContainer {...params} />
        </div>
    );
};

export default ProductSelector;
