import * as React from "react";
import styles from "./Products.css";
import SearchBox from "./SearchBox";
import ProductList from "./ProductList";
import { IState } from "../../SelectorDialog/reducer";
import CategoryToggle from "./CategoryToggle";
import { injectIntl } from "../../../../../view/intl";
import type { Intl } from "../../../../../view/intl/injectIntl";

type Props = {
    onManageCategoriesClick: () => void;
    applyFilters: Function;
    state: IState;
    dispatch: React.Dispatch<any>;
    selectedCategoryId: string;
    intl: Intl;
};

const messages = {
    toggleCategoryTitle: "msg: component.webshop.featuredProducts.toggleCategoryTitle {Feature entire category}",
    toggleCategoryDesc:
        "msg: component.webshop.featuredProducts.toggleCategoryDesc {New products in this category will be featured automatically.}",
    manageCategories: "msg: component.webshop.featuredProducts.manageCategories {Manage categories}",
    cannotSelectProductsWithCategoriesSelected:
        // eslint-disable-next-line max-len
        "msg: component.webshop.featuredProducts.cannotSelectProductsWithCategoriesSelected {It’s not possible to select specific products, while an entire category is featured.}"
};

const ProductsContainer = ({ intl, applyFilters, state, dispatch, selectedCategoryId, onManageCategoriesClick, ...props }: Props) => {
    return (
        <div className={styles.container}>
            <SearchBox onSearch={str => applyFilters({ productName: str })} />
            {selectedCategoryId !== "" ? (
                <div className={styles.categoryToggleContainer}>
                    <CategoryToggle state={state} dispatch={dispatch} selectedCategoryId={selectedCategoryId} />
                    <div className={styles.categoryToggleContent}>
                        <div className={styles.categoryToggleContentTitle}>{intl.msgJoint(messages.toggleCategoryTitle)}</div>
                        <div className={styles.categoryToggleContentDesc}>{intl.msgJoint(messages.toggleCategoryDesc)}</div>
                    </div>
                    <div className={styles.categoryToggleLink} onClick={onManageCategoriesClick}>
                        {intl.msgJoint(messages.manageCategories)}</div>
                </div>
            ) : null}
            {state.categories.length ? (
                <div className={styles.categoryToggleWarning}>{intl.msgJoint(messages.cannotSelectProductsWithCategoriesSelected)}</div>
            ) : null}
            {/* @ts-ignore */}
            <ProductList state={state} dispatch={dispatch} {...props} />
        </div>
    );
};

export default injectIntl(ProductsContainer);
