import CALL_API from "../../../../../../wbtgen/src/redux/middleware/api/CALL_API";
import { TaskItemKeyT } from "./types";

export {
    OPEN_MARKETGOO_TASK_BANNER_ACTION,
    CLOSE_MARKETGOO_TASK_BANNER_ACTION,
    MARKETGOO_TASK_DONE_ACTION,
    DISMISS_MARKETGOO_TASK_BANNER_ACTION,
} from '../../../../../../wbtgen/src/components/Marketgoo/actionTypes';

export { SET_SIDE_BAR_TAB_ACTION } from "../../SideBar/actions";

export const
    // Get Marketgoo account
    GET_MARKETGOO_ACCOUNT_REQUEST_ACTION = 'GET_MARKETGOO_ACCOUNT_REQUEST_ACTION',
    GET_MARKETGOO_ACCOUNT_SUCCESS_ACTION = 'GET_MARKETGOO_ACCOUNT_SUCCESS_ACTION',
    GET_MARKETGOO_ACCOUNT_FAILURE_ACTION = 'GET_MARKETGOO_ACCOUNT_FAILURE_ACTION',

    // Site tasks cta actions
    HANDLE_SITE_TASKS_CTA_ACTION = 'HANDLE_SITE_TASKS_CTA_ACTION',

    SAVE_MARKETGOO_IN_PREFERENCES_ACTION = 'SAVE_MARKETGOO_IN_PREFERENCES_ACTION',

    SET_PENDING_MARKETGOO_BANNER_ACTION = 'SET_PENDING_MARKETGOO_BANNER_ACTION',

    GO_TO_EDITOR_TO_PUBLISH_ACTION = 'GO_TO_EDITOR_TO_PUBLISH_ACTION';

export const
    handleSiteTasksCtaAction = (taskItemKey: TaskItemKeyT, data: any) =>
        ({ type: HANDLE_SITE_TASKS_CTA_ACTION, payload: { taskItemKey, data } });

export const
    saveMarketgooInPreferences = (payload: Record<string, any>) => ({
        type: SAVE_MARKETGOO_IN_PREFERENCES_ACTION,
        payload
    }),
    getMarketgooAccountAction = () => ({
        [CALL_API]: {
            types: [
                GET_MARKETGOO_ACCOUNT_REQUEST_ACTION,
                GET_MARKETGOO_ACCOUNT_SUCCESS_ACTION,
                GET_MARKETGOO_ACCOUNT_FAILURE_ACTION
            ],
            endpoint: 'getMarketgooAccount',
        }
    } as unknown as Action);

export const setPendingMarketgooBannerAction = (bannerId: string) => ({
    type: SET_PENDING_MARKETGOO_BANNER_ACTION,
    payload: { bannerId }
});

export const
    UPDATE_MARKETGOO_SCORE_HISTORY_ACTION = "UPDATE_MARKETGOO_SCORE_HISTORY_ACTION",
    updateMarketgooScoreHistory = (scores) => ({
        type: UPDATE_MARKETGOO_SCORE_HISTORY_ACTION,
        payload: scores,
    });
