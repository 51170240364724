import { epicStateAppSel } from "../../../../epics/selectors";
import { templateSelectorPreviewVAT } from './valueActionType';
import { makeEpicStateSelector } from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";

const templateSelectorPreviewEpicSelector = makeEpicStateSelector(templateSelectorPreviewVAT),
    selectedColorPickerTypeSelector = epicStateAppSel(templateSelectorPreviewVAT, ['selectedColorPickerPalette', 'type']),
    templateSelectorPreviewStateSelector = (state) => templateSelectorPreviewEpicSelector(state),
    isNextGenPreviewSelector = (state) => templateSelectorPreviewStateSelector(state).isNextGenPreview,
    previewCurrentPageIdSelector = state => state.themePreviewDataForOnboarding?.uuidOfHomePage;

const ROPreviewCurrentPageIdSelector =
    receiveOnly(templateSelectorPreviewVAT, previewCurrentPageIdSelector);

export {
    selectedColorPickerTypeSelector,
    templateSelectorPreviewStateSelector,
    ROPreviewCurrentPageIdSelector,
    isNextGenPreviewSelector,
};
