import React from "react";
import { connect } from "react-redux";
import injectIntl from "../../../../view/intl/injectIntl";
import { Dialog, DialogBody, DialogTitleBox, DialogFooter } from "../../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";
import { HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG, FEATURED_PRODUCTS_SET_ITEMS } from "./actionTypes";

import ProductSelector from "../ProductSelector/components/ProductSelector";
import styles from "../ProductSelector/components/ProductSelector.css";
import DialogFooterContent from "./DialogFooterContent";
import FeaturedProductKind from "../kind";

import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { WEBSHOP_ONBOARD_STATUS } from "../../WebShop/epics/onboardStatus/valueActionType";
import reducer from "./reducer";

const FeaturedProductsSelectionDialog = injectIntl(
    ({ dispatch: appDispatch, featuredProducts = [], categories = [], hasOnlyDigitalShop }) => {
        const onClose = () => appDispatch({ type: HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG });
        const isUpdate =
            (Array.isArray(featuredProducts) && featuredProducts.length > 0) || (Array.isArray(categories) && categories.length > 0);
        const [state, dispatch] = React.useReducer(reducer, {
            products: featuredProducts,
            categories,
            isDirty: false,
            allCategoriesResponse: []
        });
        const isCategoryOrProductsSelected = state.categories.length || state.products.length;

        return (
            <Dialog onClose={onClose}>
                <DialogTitleBox title="msg: component.webshop.featuredProducts.dialog.title {Choose one or multiple products to feature}" />
                <DialogBody className={styles.dialogBody}>
                    <ProductSelector
                        appDispatch={appDispatch}
                        state={state}
                        dispatch={dispatch}
                        hasOnlyDigitalShop={hasOnlyDigitalShop}
                    />
                </DialogBody>
                <DialogFooter
                    noTopBorder
                    className={styles.dialogFooter}
                    contentClassName={styles.dialogFooterContent}
                    disabled={!isCategoryOrProductsSelected}
                    mctaText={
                        !isUpdate
                            ? "msg: common.addToPage {Add to page}"
                            : "msg: component.webshop.featuredProducts.dialog.UpdateProducts {Update products}"
                    }
                    mctaHandler={() => {
                        return isCategoryOrProductsSelected
                            ? appDispatch({
                                type: FEATURED_PRODUCTS_SET_ITEMS,
                                payload: {
                                    componentKind: FeaturedProductKind,
                                    isUpdate,
                                    products: [...state.products].map(id => `product: '${id}'`).join(),
                                    categories: state.categories.join(',')
                                }
                            })
                            : null;
                    }}
                    sctaHandler={onClose}
                >
                    <DialogFooterContent state={state} dispatch={dispatch} />
                </DialogFooter>
            </Dialog>
        );
    }
);

const width = 1162,
    height = 820;

const mapStateToProps = appState => {
    const { hasOnlyDigitalShop } = makeEpicStateSelector(WEBSHOP_ONBOARD_STATUS)(appState);
    return { hasOnlyDigitalShop };
};

const config = getCenteredDialogConfig({
    width,
    height,
    component: connect(mapStateToProps)(FeaturedProductsSelectionDialog),
    draggableClassName: ""
});

const getDialogDimensions = browserHeight => Math.min(Math.max(browserHeight - 100, 475), height);

export default {
    ...config,
    confFactory: ({ browserWidth, browserHeight }) => {
        const height = getDialogDimensions(browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    moveToCenter: true
};
