import { isEmpty } from 'ramda';
import { default as dynamicOnboardingEpicVat } from "./valueActionType";
import { dynamicPreviewEpic } from './dynamicPreviewEpic';
import subscriptionDataVat from '../../../App/epics/subscriptionData/valueActionType';
import { makeEpicStateSelector } from '../../../../epics/makeEpicStateSelector';

export const
    dynamicOnboardingEpicSelector = makeEpicStateSelector(dynamicOnboardingEpicVat),
    dynamicPreviewSelector = makeEpicStateSelector(dynamicPreviewEpic.valueActionType),
    dynamicOnboardingStateSelector = (state) => dynamicOnboardingEpicSelector(state),
    gmbKeySelector = (state) => dynamicOnboardingStateSelector(state).gmbKey,
    businessNameSelector = (state) => dynamicOnboardingStateSelector(state).businessName,
    keywordsSelector = (state) => dynamicOnboardingStateSelector(state).keywords,
    languageSelector = (state) => dynamicOnboardingStateSelector(state).language,
    contactInfoSelector = (state) => dynamicOnboardingStateSelector(state).contactInfo,
    isGroupSelector = (state) => dynamicOnboardingStateSelector(state).isGroup,
    isGroupSetSelector = (state) => dynamicOnboardingStateSelector(state).isGroupSet,
    conceptSelector = (state) => dynamicOnboardingStateSelector(state).concept,
    optionSetSelector = (state) => conceptSelector(state).optionSet,
    purposeSelector = (state) => dynamicOnboardingStateSelector(state).purpose,
    isLastStepSelector = (state) => dynamicOnboardingStateSelector(state).isLastStep,
    failedAttemptSelector = (state) => dynamicOnboardingStateSelector(state).failedAttempt,
    categoryErrorBannerSelector = (state) => dynamicOnboardingStateSelector(state).showCategoryErrorBanner,
    errorTypeSelector = (state) => dynamicOnboardingStateSelector(state).errorType,
    changesInOnboardingSelector = (state) => {
        const { isNewGmbKey, concept } = dynamicOnboardingStateSelector(state);
        return isNewGmbKey || !isEmpty(concept);
    },
    animationDataSelector = (state) => dynamicOnboardingStateSelector(state).animationData,
    catchAllConceptKeySelector = (state) => dynamicOnboardingStateSelector(state).catchAllConceptKey,
    subscriptionTypeSelector = (state) => makeEpicStateSelector(subscriptionDataVat)(state)?.subscriptionType,
    synonymsSelector = (state) => dynamicOnboardingStateSelector(state).synonyms,
    dynamicTemplateIsLoadingSelector = (state): boolean => dynamicOnboardingStateSelector(state).isLoading,
    dtIsSilentLoadingSelector = (state): boolean => dynamicOnboardingStateSelector(state).isSilentLoading,

    editPreviewSelector = (state) => dynamicPreviewSelector(state).editPreview,
    editPreviewEtagSelector = (state) => dynamicPreviewSelector(state).editPreviewEtag,
    staticTextComponentsSelector = (state) => dynamicPreviewSelector(state).staticTextComponents,
    dynamicPreviewSectionsStateSelector = (state) => dynamicPreviewSelector(state).sectionsState,
    dynamicPreviewSectionsLoadingSelector = (state) => !!dynamicPreviewSelector(state).sectionsState?.sectionDataLoading;
