/* eslint-disable max-len */
import type { AppStore } from '../../redux/modules/flowTypes';
import { DASHBOARD_ROUTE } from '../../../../server/shared/routes';

import {
    TOP_BAR_WHATS_NEW_OPTION_CLICKED_ACTION,
    CLOSE_WHATS_NEW_DIALOG_ACTION,
    HIDE_WHATS_NEW_TIP_ACTION,
    openWhatsNewDialogAction,
    showWhatsNewTipAction,
    hideWhatsNewTipAction,
} from './actions';
import { WORKSPACE_READY } from '../Workspace/actionTypes';
import { savePreferencesAction } from '../Preferences/actions';
import { WHATS_NEW_RELEASE_DATE } from './constants';
import { preferencesSettingAppSel } from '../Preferences/selectors';
import { Lit } from '../../lit';
import localStorage from '../../utils/localStorage';
import { showWelcomeMessageOnTemplateImportKey } from '../WelcomeMessage/epic/index';
import { whatsNewCountAppSel } from './selectors';
import isTestEnv from '../../debug/isTestEnv';
import { WHATS_NEW_TIP_APPEARANCE_TIMEOUT } from './tip/constants';
import { closeDialogByIdAction } from '../App/actionCreators/index';
import { WHATS_NEW_DIALOG_ID } from './dialog/dialogId';
import { TOP_BAR_OPEN_POPUP } from '../TopBar/actionTypes';
import { Popups } from '../TopBar/constants';
import { isDemoSubscriptionTypeAppSel } from '../../../demo/modules/selectors';
import { showQuickTourAppSel } from '../QuickTour/selectors';
import { STOP_QUICK_TOUR } from '../QuickTour/actionTypes';
import { OPEN_SEO_SCAN_FROM_WHATS_NEW } from "../../../../src/dashboard/src/components/WhatsNew/actions";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "../UnsavedChanges/actionTypes";
import { setDashboardSessionDefaultPage } from '../../../../src/dashboard/src/components/SideBar/utils';
import { MARKETGOO_PAGE_SIDE_BAR_ITEM_ID } from "../../../../src/dashboard/src/components/Pages/Marketgoo/constants";
import { navigateToLocationAction } from "../../redux/middleware/window/actionCreators";
import { checkUnsavedChangesAction } from "../UnsavedChanges/actionCreators";
import { dialogManagerStateFromAppStateSelector, openedDialogIdsSelector, topMostDialogIdSelector } from '../DialogManager/epic/selectors';
import { TutorialVideoDialogId } from '../TopBar/view/dialogIds';
import openDialogAC from '../App/actionCreators/openDialog';
import { WHATS_NEW_POPUP_DIALOG_ID } from './dialog/WhatsNewPopupDialog';

const LATEST_WHATS_NEW_DIALOG_ID = 48;

export const whatsNewMiddleware = (store: AppStore) => {
    const scheduleTip = () => {
        // TODO: handle via sinon use fake timers
        setTimeout(() => {
            const
                appState = store.getState(),
                showWhatsNewTipFromPref = !preferencesSettingAppSel(Lit.hideWhatsNewTip)(appState),
                count = whatsNewCountAppSel(appState),
                quickTourIsHidden = !showQuickTourAppSel(appState),
                tutorialVideoisHidden = !openedDialogIdsSelector(dialogManagerStateFromAppStateSelector(appState)).includes(TutorialVideoDialogId);
            if (showWhatsNewTipFromPref && count && quickTourIsHidden && tutorialVideoisHidden) {
                store.dispatch(showWhatsNewTipAction());
            }
        }, isTestEnv() ? 0 : WHATS_NEW_TIP_APPEARANCE_TIMEOUT * 1000);
    };

    let isCheckUnsavedChanges = false;

    return (next: Dispatch) => (action: AnyAction) => {
        if (action.type === WORKSPACE_READY) {
            // set default what's new timestamp

            const
                appState = store.getState(),
                isDemo = isDemoSubscriptionTypeAppSel(appState),
                topMostDialogId = topMostDialogIdSelector(dialogManagerStateFromAppStateSelector(appState)),
                newDomain = localStorage.get(showWelcomeMessageOnTemplateImportKey, true) === 'yes';

            if (!isDemo) {
                if (newDomain) {
                    store.dispatch(savePreferencesAction({
                        whatsNewLastSeenTimestamp: new Date().getTime(),
                        whatsNewPopupSeenId: LATEST_WHATS_NEW_DIALOG_ID
                    }));
                } else {
                    const newPreferences: any = {};
                    const prefWhatsNewLastSeenTimestamp =
                        preferencesSettingAppSel(Lit.whatsNewLastSeenTimestamp)(appState);
                    const currentWhatsNewPopupIdSeen = preferencesSettingAppSel(Lit.whatsNewPopupSeenId)(appState);
                    const whatsNewPopupSeen = currentWhatsNewPopupIdSeen && currentWhatsNewPopupIdSeen >= LATEST_WHATS_NEW_DIALOG_ID;

                    if (!prefWhatsNewLastSeenTimestamp) {
                        newPreferences.whatsNewLastSeenTimestamp = new Date(WHATS_NEW_RELEASE_DATE).getTime();
                    }

                    if (!topMostDialogId && !whatsNewPopupSeen) {
                        newPreferences.whatsNewPopupSeenId = LATEST_WHATS_NEW_DIALOG_ID;
                        store.dispatch(openDialogAC(WHATS_NEW_POPUP_DIALOG_ID, { whatsNewId: LATEST_WHATS_NEW_DIALOG_ID, showSVG: true }));
                    }

                    if (Object.keys(newPreferences).length) {
                        store.dispatch(savePreferencesAction(newPreferences));
                    }
                }
            }

            if (newDomain) {
                if (isDemo) {
                    store.dispatch(savePreferencesAction({
                        whatsNewPopupSeenId: LATEST_WHATS_NEW_DIALOG_ID
                    }));
                }
                localStorage.remove(showWelcomeMessageOnTemplateImportKey);
            }
        }

        if (action.type === TOP_BAR_WHATS_NEW_OPTION_CLICKED_ACTION) {
            return store.dispatch(openWhatsNewDialogAction());
        }

        if (action.type === CLOSE_WHATS_NEW_DIALOG_ACTION) {
            const count = whatsNewCountAppSel(store.getState());
            if (count) {
                store.dispatch(savePreferencesAction({
                    whatsNewLastSeenTimestamp: new Date().getTime(),
                }));
            }
            return store.dispatch(closeDialogByIdAction(WHATS_NEW_DIALOG_ID));
        }

        if (action.type === HIDE_WHATS_NEW_TIP_ACTION) {
            store.dispatch(savePreferencesAction({
                [Lit.hideWhatsNewTip]: true,
            }));
        }

        if (action.type === TOP_BAR_OPEN_POPUP && action.payload.id === Popups.USER_PROFILE) {
            const showWhatsNewTipFromPref = !preferencesSettingAppSel(Lit.hideWhatsNewTip)(store.getState());
            // TODO: write test for this check
            if (showWhatsNewTipFromPref) {
                store.dispatch(hideWhatsNewTipAction());
            }
        }

        // handle what's new tip
        if (action.type === WORKSPACE_READY) {
            scheduleTip();
        }

        if (action.type === STOP_QUICK_TOUR) {
            const showWhatsNewTipFromPref = !preferencesSettingAppSel(Lit.hideWhatsNewTip)(store.getState());
            if (showWhatsNewTipFromPref) {
                scheduleTip();
            }
        }

        if (action.type === OPEN_SEO_SCAN_FROM_WHATS_NEW) {
            isCheckUnsavedChanges = true;
            store.dispatch(checkUnsavedChangesAction());
        }

        if (action.type === CANCEL_UNSAVED_CHANGES) {
            isCheckUnsavedChanges = false;
        }

        if (action.type === UNSAVED_CHANGES_CHECKED) {
            if (isCheckUnsavedChanges) {
                setDashboardSessionDefaultPage(MARKETGOO_PAGE_SIDE_BAR_ITEM_ID);
                store.dispatch(navigateToLocationAction(DASHBOARD_ROUTE));
            }
        }

        return next(action);
    };
};
