import React from "react";
import cx from "classnames";
import { injectIntl } from "../../../../view/intl";
import type { MsgJointInput, Intl } from "../../../../view/intl";
import {
    Dialog,
    DialogBody,
    DialogFooterBare
} from "../../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { getCenterPositionForDialog } from '../../../DialogManager/utility';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/Dialog";
import {
    PrimaryButton,
    SecondaryButton
} from "../../../../view/common/Button";

import styles from './UnSavedChangesDialog.css';

type Props = {
    bodyText: MsgJointInput,
    cancelBtnText:MsgJointInput,
    successBtnText:MsgJointInput,
    onSaveClick: React.MouseEventHandler,
    onCancelClick: () => void,
    intl: Intl,
};

const UnsavedChangesDialogCom = injectIntl((
    {
        bodyText,
        cancelBtnText,
        successBtnText,
        onSaveClick,
        onCancelClick,
        intl
    }: Props
) => {
    return (
        <React.Fragment>
            <div className={cx(styles.transparentBackground)} />
            <Dialog
                closeBtnClassName={styles.closeButton}
                className={cx(styles.dialogMain)}
                onClose={onCancelClick}
                stripType={StripTypes.NONE}
            >
                <DialogBody className={cx(styles.dialogBody)}>
                    <div className={styles.title}>{intl.msgJoint('msg: pageChangesDialog.title {Unsaved Changes}')}</div>
                    <div className={styles.description}>
                        {intl.msgJoint(bodyText)}
                    </div>
                </DialogBody>
                <DialogFooterBare className={styles.dialogFooter}>
                    <SecondaryButton
                        onClick={onCancelClick}
                        className={styles.button}
                    >
                        {intl.msgJoint(cancelBtnText)}
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={onSaveClick}
                        autoFocus
                        className={styles.button}
                    >
                        {intl.msgJoint(successBtnText)}
                    </PrimaryButton>
                </DialogFooterBare>
            </Dialog>
        </React.Fragment>

    );
});

const
    width = 560,
    height = 270;

const config = getCenteredDialogConfig({
    width,
    height,
    component: UnsavedChangesDialogCom,
    draggableClassName: '',
    dialogBackgroundClassName: styles.dialogBackground,
    dialogClassName: styles.dialogManagerContainer
});

const getDialogDimensions = (browserHeight) => Math.min(Math.max(browserHeight - 100, 475), height);

export default {
    ...config,
    confFactory: ({ browserWidth, browserHeight }) => {
        const height = getDialogDimensions(browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    moveToCenter: true,
};
